<template>
  <div class="ag-padding-h-v-10">

    <!-- 搜索 -->
    <div class="ag-filter-container">
      <AgTableFilterInput v-model="agFilterQuery.id" placeholder="id" @input="onAgSearch(true)" width="80px"/>
      <AgTableFilterInput v-model="agFilterQuery.title" placeholder="名称" @input="onAgSearch(true)"/>
      <AgTableFilterTime v-model="agFilterQuery.create_time" @input="onAgSearch(true)"/>
      <el-button class="filter-item" type="default" icon="el-icon-refresh-right" @click="onAgSearchReset">重置</el-button>
    </div>
    <div class="ag-filter-container">
      <el-button :disabled="!agAuth.add" class="filter-item" size="mid" type="primary" @click="onAddClick">添加
      </el-button>
      <el-button :disabled="!agAuth.remove && agSelections.length == 0" class="filter-item" size="mid" type="danger"
                 @click="onMultiDelClick">删除
      </el-button>
      <el-button style="float: right" size="mid" class="filter-item"
                 :icon="agListLoading?'el-icon-loading':'el-icon-refresh'" type="success"
                 @click="onAgSearch(false)">
      </el-button>
    </div>
    <!-- 列表-->
    <el-table
      border fit highlight-current-row
      style="width: 100%;"
      :key="agTableKey"
      :data="agListData"
      v-loading="agListLoading"
      :row-class-name="agTableRowClassName"
      :header-cell-class-name="agTableHeaderClass"
      @selection-change="onAgTableSelectionChange"
      @sort-change="onAgTableSorChange"
    >
      <el-table-column type="selection" align="center" width="55"/>
      <el-table-column sortable="custom" label="ID" prop="id" align="center" width="80"/>

          <el-table-column label="页面路径" prop="path" align="center" :show-overflow-tooltip="true"/>
          <el-table-column label="名称" prop="name" align="center" :show-overflow-tooltip="true"/>
      <el-table-column sortable="custom" label="创建时间" prop="create_time" align="center" width="180"/>
      <el-table-column label="操作" align="center" width="200" class-name="small-padding">
        <template slot-scope="{row}">
          <el-button type="success" size="mini" @click.native.stop="onDetailClick(row)">详情</el-button>
          <el-button :disabled="!agAuth.edit" type="primary" size="mini" @click.native.stop="onEditClick(row)">编辑
          </el-button>
          <el-button :disabled="!agAuth.remove" type="danger" icon="el-icon-delete" size="mini"
                     @click.native.stop="onDelClick(row)"/>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页-->
    <AgPagination
      :total="agTotal?agTotal:0"
      :page.sync="agPageQuery.current_page"
      :limit.sync="agPageQuery.per_page"
      @pagination="onAgPageChange"
    />
    <AgDrawer
      :visible.sync="agDrawerData.visible"
      :drawer="agDrawerData"
      @onDrawerAction="onAgDrawerAction"
    />
  </div>
</template>

<script>
import Index from './index'
import AgDrawer from "@/ag/components/AgDrawer";
import AgTableFilterInput from "@/ag/components/AgTable/AgTableFilterInput";
import AgTableFilterTime from "@/ag/components/AgTable/AgTableFilterTime";
import AgPagination from "@/ag/components/AgPagination";
import AgTableColumnImage from "@/ag/components/AgTable/AgTableColumnImage";

export default {
  name: 'PagesList',
  components: {AgTableColumnImage, AgPagination, AgTableFilterTime, AgTableFilterInput, AgDrawer},
  mixins: [Index]
}
</script>

<style scoped lang="sass">
@import "./index"
</style>
