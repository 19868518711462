<template>
  <el-select v-model="currentValue"
             :size="size"
             :disabled="disabled"
             :multiple="multiple"
             :placeholder="placeholder"
             :clearable="clearable"
             filterable
             remote
             default-first-option
             class="ag-form-select"
             :remote-method="remoteMethod"
             @visible-change="onVisibleChange"
             @change="handleModelInput"
  >
    <el-option
      v-for="(item) in options"
      :key="item.value"
      :label="item[props.label]" :value="item[props.value]"/>
  </el-select>
</template>

<script>/**
 * 用于网络请求回来的
 */
export default {
  name: 'AgFormSelectEx',
  props: {
    props: {
      type: [Object],
      default() {
        return {
          label: 'name',
          value: 'id',
        }
      }
    },
    multiple: {
      type: [Boolean],
      default: false
    },
    clearable: {
      type: [Boolean],
      default: false
    },
    placeholder: {
      type: [String],
      default: null
    },
    size: {
      type: [String],
      default: 'medium'
    },
    value: {
      type: [Number, String, Array],
      default: null
    },
    disabled: {
      type: [Boolean],
      default: false
    },
    selectApi: {
      type: [Object],
      default: null
    }
  },
  data() {
    return {
      currentValue: this.value,
      options: []
    }
  },
  created() {
    this.requestSelect(this.currentValue, '')
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue
      this.requestSelect(this.currentValue)
    }
  },
  methods: {
    onVisibleChange(visible) {
      if (visible && this.currentValue == '') {
        this.requestSelect()
      }
    },
    handleModelInput(event) {
      this.$emit('input', this.currentValue)
    },
    remoteMethod(query) {
      this.requestSelect(null, query)
    },
    requestSelect(id = null, query = null) {
      this.selectApi
        .setParams({id, query})
        .exec().then((res) => {
        this.options = res.list
      })
    },
  }
}
</script>

<style scoped>

</style>
