import {BaseDto} from "@/ag/entities/dto/BaseDto";

export default class ParamsDto extends BaseDto{

      id = '' // 自增id
      mini_id = '' // 关联小程序id
      key = '' // 键名
      desc = '' // 描述
      create_time = '' // 创建时间
  
  $rules = {
    mini_id : this.required('必填'),
    key : this.required('必填'),
  }
  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }

  toDto() {
    let dto = super.toDto();
    return dto
  }
}
