import {FormMixin} from "@/ag/mixins/FormMixin";

export default {
  mixins: [FormMixin],
  data() {
    return {
      ACTIONS: {
        CHECK: '1',
        LOGIN: '2',
      },
      CARD_VIEWS: {
        PHONE_LOGIN: '1',
        CODE_LOGIN: '2',
        SELECT_ENT: '3',
      },
      action: '',
      cardType: ''
    }
  },
  created() {
    this.action = this.ACTIONS.CHECK
    this.cardType = this.CARD_VIEWS.PHONE_LOGIN
  },
  methods: {
    setAction(action) {
      this.action = action
      if (this.action === this.ACTIONS.CHECK){
        this.form.code = ''
        this.form.password = ''
      }
    },
    setCardType(card) {
      this.cardType = card
    },
    onBack() {
      this.setAction(this.ACTIONS.CHECK)
      this.setCardType(this.CARD_VIEWS.PHONE_LOGIN)
    },
    setCodeLoginType(){
      // 发生短信验证码成功后在切换
      this.cardType = this.CARD_VIEWS.CODE_LOGIN
    }
  }
}
