import AppletsBaseApi from "@/api/applets/AppletsBaseApi";
import PagingKit from "@/ag/kits/PagingKit";
import PagesItemRes from "@/entities/applets/res/pages/PagesItemRes";
import PagesDetailRes from "@/entities/applets/res/pages/PagesDetailRes";

export default class PagesApi {
  /**
   * 属性分页
   * @param data
   * @returns { AppletsBaseApi }
   */
  static page(data = {}) {
    return new AppletsBaseApi('/miniProgram/miniPage/page', 'post')
      .setData(data)
      .setPageItemClassName(PagesItemRes)
  }

  /**
   * 获取分页
   * @param data
   * @returns {PagingKit}
   */
  static paging(data) {
    return new PagingKit(PagesApi.page(data), 10)
  }

  /**
   * 获取详情
   * @param id
   * @returns { AppletsBaseApi }
   */
  static detail(id) {
    return new AppletsBaseApi('/miniProgram/miniPage/detail', 'get')
      .setParams({id})
      .setClassName(PagesDetailRes)
  }

  /**
  * 保存
  * @param data
   * @returns { AppletsBaseApi }
   */
  static update(data) {
    return new AppletsBaseApi('/miniProgram/miniPage/update', 'post')
      .setData(data)
      .setClassName(PagesDetailRes)
  }

  /**
   * 创建
   * @param data
   * @returns { AppletsBaseApi }
   */
  static create(data) {
    return new AppletsBaseApi('/miniProgram/miniPage/create', 'post')
      .setData(data)
      .setClassName(PagesDetailRes)
  }

  /**
   * 删除
   * @param ids
   * @returns { AppletsBaseApi }
   */
  static remove(ids) {
    return new AppletsBaseApi('/miniProgram/miniPage/remove', 'post')
      .setData({ids})
  }
}
