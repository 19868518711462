import {BaseMixin} from "@/ag/mixins/BaseMixin";
// page 管理
export const DialogPageMixin = {
  mixins: [BaseMixin],
  data() {
    return {
      agDialogData: {
        visible: false,  // 对话框可视
        action: '',      // 哪个对话框
        preload: {},     // 携带信息
        component: null,  // 对话框组件
      }
    }
  },
  created() {
    this._dialogListeners = {}
  },
  methods: {
    /**
     * 创建对话框
     * @param dialogData 与agDialogData 数据结构一致
     * @param preload 携带信息 会传入dialog组件里面
     * @param dialogStyle dialog的样式，详见  AgDialog组件
     */
    agCreateDialog(dialogData, preload = {}, dialogStyle = {}) {
      let data = {...dialogData, visible: true}
      data.preload = {...data.preload, ...preload}
      data.dialogStyle = {...data.dialogStyle, ...dialogStyle}
      this.agDialogData = data
    },
    // 关闭对话框
    agCloseDialog() {
      this.agDialogData = {visible: false};
    },
    // 默认处理抽屉事件
    onAgDialogAction({dialog, data}) {
      const {action} = dialog
      const listener = this._dialogListeners[action]
      if (listener) {
        listener({dialog, data})
      }
    },
    // 默认处理抽屉事件
    agDialogBindAction(action, listener) {
      this._dialogListeners[action] = listener
    }
  }
}
