export const Macros = {
  NO: 1,
  YES: 2,
  NO_LOGIN: 4003,
  YesNoStr(value) {
    if (Macros.YES == value) {
      return '是'
    } else {
      return '否'
    }
  },
  YesNoList() {
    return [{label: '否', value: 1}, {label: '是', value: 2}]
  },
  StatusList() {
    return [{label: '下架', value: 1}, {label: '上架', value: 2}]
  },
  SlabStatusList() {
    return [{label: '下架', value: 1}, {label: '上架', value: 2}, {label: '待上架', value: 3}]
  },
  TryTypeList() {
    return [{label: '正式', value: 1}, {label: '试用', value: 2}]
  },
  enableList() {
    return [{label: '禁用', value: 1}, {label: '启用', value: 2}]
  },
  videoDirection() {
    return [{label: '横屏', value: 1}, {label: '竖屏', value: 2}]
  },
  typeList() {
    return [{label: '升级', value: 1}, {label: '弹窗', value: 2}, {label: '引导', value: 3}]
  },
  OrderStatusList() {
    return [{label: '待指派', value: 1}, {label: '跟进中', value: 2}]
  },
  DecorationOrderStatusList() {
    return [
      // {label: '待分配', value: 1},
      {label: '待指派', value: 2},
      {label: '跟进中', value: 3},
      // {label: '待派单', value: 4},
      // {label: '无效', value: -1},
    ]
  },
  ProductStatusList() {
    return [
      {label: '待付款', value: 1},
      {label: '待发货', value: 2},
      {label: '待收货', value: 3},
      {label: '已完成', value: 4},
      {label: '失效', value: 5},
    ]
  },
  AuditStatusList() {
    return [
      {label: '待审核', value: 1},
      {label: '已通过', value: 2},
      {label: '不通过', value: 3},
      {label: '作废', value: 4},
    ]
  },
  ProjectStatusList() {
    return [
      {label: '对接中', value: 1},
      {label: '已签合同', value: 2},
      {label: '已失效', value: 3},
    ]
  },
  ServiceTypeList() {
    return [{label: '石材工程', value: 2}, {label: '石材交易', value: 1}]
  },
  SalesmanTypeList() {
    return [{label: '全职', value: 1}, {label: '兼职', value: 2}]
  },
  SalesmanStatusList() {
    return [{label: '离职', value: 1}, {label: '在职', value: 2}]
  },
  SexList() {
    return [{label: '男', value: 1}, {label: '女', value: 2}]
  },
  BillTypeList() {
    return [{label: '入账', value: 1}, {label: '出账', value: 2}]
  },
}
