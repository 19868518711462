<template>
  <div class="ag-upload-content" :class="isMini?'ag-upload-content-mini':''">
    <el-switch
      v-if="isSet"
      v-model="isEditSet"
      active-color="#13ce66"
      active-text="编辑模式">
    </el-switch>
    <br/>
    <draggable v-model="imageList" class="ag-inline" @end="onDragEnd">
      <transition-group>
        <div v-for="item in imageList" :key="item.index" class="el-upload-list--picture-card" >
          <span class=" el-upload-list__item" :style="{height: isSet?'100%':''}">
            <video v-if="isVideo" style="height: 100%;width:100%;display: block" :src="item.url"></video>
            <el-image v-else ref="myImg" class="ag-upload-img" :src="item.url" fit="contain"
                      :preview-src-list="getImageUrlList()"/>
            <span class="el-upload-list__item-actions ag-upload-actions">
              <span v-if="!isVideo" class="el-upload-list__item-delete" @click="openWindow(item.index)">
                <i class="el-icon-download" style="font-size: 20px"/>
              </span>
              <!--              <span v-show="isSingleAndOneSelect()" class="el-upload-list__item-delete" @click="reUpload(item)">-->
              <!--                <i class="el-icon-upload" style="font-size: 24px"/>-->
              <!--              </span>-->
              <span class="el-upload-list__item-preview" @click="previewImage(item,item.index)">
                <i v-if="isVideo" class="el-icon-video-play" style="font-size: 24px;"/>
                <i v-else class="el-icon-zoom-in" style="font-size: 24px;"/>
              </span>
              <!--              <span v-if="crop && !isVideo" class="el-upload-list__item-delete" @click="cropImage(item)">-->
              <!--                <i class="el-icon-crop" style="font-size: 24px"/>-->
              <!--              </span>-->
              <span v-if="!isVideo" class="el-upload-list__item-delete" @click="reUploadImage(item)">
                <i class="el-icon-refresh-left" style="font-size: 24px"/>
              </span>
              <span class="el-upload-list__item-delete" @click="deleteImage(item)">
                <i class="el-icon-delete" style="font-size: 24px"/>
              </span>

            </span>
            <div v-if="isSet">
              <el-input size="mini" v-model="item.title" @change="emitImage" placeholder="请输入标题" clearable/>
              <ag-form-select size="mini" :select-list="itemSelects" v-model="item.action_type" @change="emitImage"
                              placeholder="选择类型"/>
              <el-input type="textarea" :rows="getSetInputRows()" v-model="item.action" @change="emitImage" placeholder="请输入action" clearable/>
            </div>
          </span>
        </div>
      </transition-group>
    </draggable>
    <div key="tips" v-show="isSingleAndOneSelect() && tip != ''" class="ag-upload-tips el-upload__tip">
      <span>{{ tip }}</span>
    </div>
    <el-upload
        ref="upload"
        v-show="!isSingleAndOneSelect()"
        action="#"
        class="ag-inline"
        list-type="picture-card"
        :auto-upload="true"
        :show-file-list="false"
        :multiple="multiple"
        :accept="isVideo?videoAccept:accept"
        :on-success="onUploadSuccess"
        :on-error="onUploadError"
        :http-request="onRequest"
        :before-upload="onBeforeUnload"
    >
      <i slot="default" class="el-icon-plus"/>
      <div v-if="tip != ''" slot="tip" class="ag-upload-tips el-upload__tip">
        <span>{{ tip }}</span>
      </div>
    </el-upload>
    <!-- 剪裁组件弹窗 -->
    <el-dialog
        :append-to-body="true"
        v-if="cropperModel"
        title="裁切"
        :close-on-click-modal="false"
        :visible.sync="cropperModel"
        width="950px"
        center
    >
      <ag-cropper-image
          ref="child"
          :name="cropperName"
          :image="cropperImage"
          :crop-width="cropWidth"
          :crop-height="cropHeight"
          @upload-img-success="onCropUploadSuccess"
      />
    </el-dialog>

    <!-- 重新上传 -->
    <el-upload
        ref="reUpload"
        v-show="false"
        action="#"
        class="ag-inline"
        list-type="picture-card"
        :auto-upload="true"
        :show-file-list="false"
        :on-success="onUploadSuccessEx"
        :on-error="onUploadError"
        :http-request="onRequest"
        :before-upload="onBeforeUnload"
    >
    </el-upload>
  </div>
</template>
<script>
/**
 * 用于单张图片'http://xxxx' 或者 数组['http://xxxx','http://xxxx']
 */
import {Message} from 'element-ui'
import draggable from 'vuedraggable'
import AgCropperImage from '@/ag/components/AgForm/AgFormCropper/index'
import SparkMD5 from 'spark-md5'
import HttpKit from '@/ag/kits/HttpKit'
import Oss from "@/core/Oss";
import OssKit from "@/ag/kits/OssKit";
import AgFormSelect from "@/ag/components/AgForm/AgFormSelect";
import {deepClone} from '@/ag/utils'

const ImageCompressor = require('js-image-compressor')
export default {
  name: 'AgFormUpload',
  components: {AgFormSelect, AgCropperImage, draggable},
  props: {
    isMini: {type: Boolean, default: false}, // mini样式
    isSet: {type: Boolean, default: false}, // Set集合
    itemSelects: {
      type: Array, default: () => {
        return []
      }
    }, // set的下拉选择
    app: {type: String, default: 'oa'},
    isVideo: {type: Boolean, default: false},
    // 是否多图
    multiple: {type: Boolean, default: false},
    // 提示
    tip: {type: String, default: ''},
    // 是否裁剪
    crop: {type: Boolean, default: false},
    // 裁剪输出宽度
    cropWidth: {type: Number, default: 600},
    // 裁剪输出高度
    cropHeight: {type: Number, default: 600},
    // 是否压缩
    compress: {type: Boolean, default: true},
    // 可上传文件类型
    accept: {type: String, default: 'image/*'},
    videoAccept: {type: String, default: 'video/*'},
    // 可上传最大文件大小  单位 M
    maxSize: {type: Number, default: 30},
    // 最大宽度
    maxWidth: {type: Number, default: 2048},
    // 最大高度
    maxHeight: {type: Number, default: 4096},
    // 压缩质量
    quality: {type: Number, default: 0.9},
    // v-model
    value: {type: [String, Array], default: null}
  },
  data() {
    return {
      cropperName: 'cropperName',
      cropperModel: false,
      cropperImage: '',
      imageList: [],
      reUploadModel: false,
      reUploadIndex: 0,
      isEditSet:false
    }
  },
  watch: {
    value(newValue) {
      this.value = newValue
      this.initImage(this.value)
    }
  },
  created() {
    this.initImage(this.value)
  },
  methods: {
    onBeforeUnload(file) {
      console.log(file)
      const sizeOfM = file.size / 1024 / 1024
      if (sizeOfM > this.maxSize) {
        Message.error('文件大小不能超过' + this.maxSize + 'M')
        return false
      }
      // 没有启动压缩
      if (!this.compress) {
        return
      }
      // 小于100K，不用处理了
      if (file.size / 1024 < 100) {
        return true
      }

      if (this.isVideo) {
        return file
      }
      return new Promise((resolve, reject) => {
        const options = {
          file,
          quality: this.quality,
          mimeType: file.type ? file.type : 'image/jpeg',
          maxWidth: this.maxWidth,
          maxHeight: this.maxHeight,
          // 压缩前回调
          beforeCompress: function (result) {
          },
          success: function (result) {
            resolve(result)
          }, // 发生错误
          error: function (msg) {
            Message.error(msg)
            reject(msg)
          }
        }
        if (file.type === 'image/gif') {
          resolve(file)
        } else {
          new ImageCompressor(options)
        }
      })
    },
    /**
     * 图片上传 网络请求
     * fileIfo
     */
    async onRequest(fileIfo) {
      console.log(fileIfo)
      // 独立上传文件
      const {data, filename, file} = fileIfo
      // 新建formDate对象
      const formData = new FormData()
      for (const key in data) {
        formData.append(key, data[key])
      }
      let fileMd5Val = await OssKit.getFileMd5(file)
      if (!fileMd5Val) {
        Message.error('文件格式不正确，请重新选择')
        return false
      }
      let fileName = file.name
      let fileKey = fileMd5Val + fileName.substring(fileName.lastIndexOf("."), fileName.length)
      let uploadSignature = await Oss.getOssSignature(this.app)
      formData.append('name', fileName)
      formData.append('key', uploadSignature.pre_name + fileKey)
      formData.append('policy', uploadSignature.policy)
      formData.append('OSSAccessKeyId', uploadSignature.access_key)
      formData.append('success_action_status', 200)
      formData.append('callback', uploadSignature.callback)
      formData.append('signature', uploadSignature.signature)
      formData.append('file', file)//必须最后添加

      return new Promise((resolve, reject) => {
        const url = uploadSignature.request_url
        HttpKit.REQUEST(url, {}, formData, "post", {"Content-Type": "multipart/form-data;"})
            .then((result) => {
              resolve(result)
            })
            .catch((result) => {
              console.error(`request url:${url}`, result)
              if (this.isShowErr) {
                this._showErr(result.msg)
              }
              reject(result)
            })
            .finally(() => {
              if (this.onLoadingChange) {
                this.onLoadingChange(false)
              }
            })
      })
    },
    async onUploadSuccess(response, file, fileList) {
      try {
        await this.beforeAppendImage(file)
        const {file_url} = response.data
        await this.appendImage(file_url)
        this.emitImage()
        Message.success((file && file.name ? file.name : '') + '上传成功')
      } catch (e) {
      }
    },
    async onUploadSuccessEx(response, file, fileList) {
      try {
        await this.beforeAppendImage(file)
        const {file_url} = response.data
        console.log('onUploadSuccessEx', file_url)
        const item = this.imageList[this.reUploadIndex]
        this.$set(this.imageList, this.reUploadIndex, {...item, url: file_url})
        this.reUploadModel = false
        Message.success('重新上传成功')
        this.emitImage()
      } catch (e) {
      }
    },
    onUploadError(err, file, fileList) {
      err = (file && file.name ? file.name : '') + '上传失败'
      Message.error(err)
    },
    /**
     * 根据输入的值初始化
     */
    initImage(inputValue) {
      this.imageList = []
      if (!inputValue) {
        return
      }
      if (typeof inputValue === 'string') {
        this.appendImage(inputValue)
      } else if (this.isSet) {
        for (const item of inputValue) {
          this.appendImage(item.url, item)
        }
      } else {
        for (const item of inputValue) {
          this.appendImage(item)
        }
      }
    },
    // 附加图片之前
    beforeAppendImage(file) {
      return true
    },
    /**
     * 附加图片连接
     * @param url 图片链接
     */
    appendImage(url, params = {}) {
      if (!url) {
        return
      }
      const index = this.imageList.length
      const item = {url, index}
      if (!this.multiple) {
        this.$set(this.imageList, 0, item)
      } else if (this.isSet) {
        this.imageList.push({...item, ...params})
        let image_list = deepClone(this.imageList)
        for (let i in image_list) {
          image_list[i].index = i;
        }
        this.imageList = image_list
      } else {
        this.imageList.push(item)
      }
      // console.log('this.imageList',this.imageList)
    },
    /**
     * 删除图片
     * @param item
     */
    deleteImage(item) {
      this.imageList.splice(item.index, 1)
      this.emitImage()
    },
    /**
     * 预览图片
     * @param item
     */
    previewImage(item, index) {
      if (this.isVideo) {
        this.openWindow(index)
      }
      this.$viewerApi({
        images: this.getPreImageList(),
        options: {
          initialViewIndex: index,
        },
      })
    },
    openWindow(index) {
      let images = this.getImageUrlList();
      window.open(images[index])
    },
    cropImage(item) {
      this.cropperModel = true
      this.cropperImage = item.url
      this.cropIndex = item.index
    },
    reUploadImage(item) {
      // this.reUploadModel = true
      this.reUploadIndex = item.index
      this.$refs['reUpload'].$children[0].$refs.input.click()
    },
    reUpload(item) {
      // 重新上传
      this.$refs['upload'].$children[0].$refs.input.click()
    },
    resetCrop() {
      this.cropperModel = false
      this.cropperImage = ''
      this.cropIndex = 0
    },
    onCropUploadSuccess(url) {
      const item = this.imageList[this.cropIndex]
      this.$set(this.imageList, this.cropIndex, {...item, url: url})
      this.resetCrop()
      Message.success('裁剪图片成功')
      this.emitImage()
    },
    getImageUrlList() {
      const list = []
      for (const item of this.imageList) {
        list.push(this.isSet ? item : item.url)
      }
      return list
    },
    getPreImageList() {
      const list = []
      for (const item of this.imageList) {
        list.push(item?.url ? item.url : item)
      }
      console.log('list222', list)
      return list
    },
    /**
     * 转递图片到父view
     */
    emitImage() {

      const urlList = this.getImageUrlList()
      if (!this.multiple) {
        if (urlList.length > 0) {
          this.$emit('input', urlList[0])
        } else {
          this.$emit('input', '')
        }
      } else {
        this.$emit('input', urlList)
      }
    },
    onDragEnd() {
      this.emitImage()
    },
    // 单选图片
    isSingleAndOneSelect() {
      const urlList = this.getImageUrlList()
      if (!this.multiple) {
        if (urlList.length > 0) {
          return true
        }
      }
      return false;
    },
    async calcFileMd5Val(file) {//计算文件md5值
      return new Promise(resolve => {
        //计算文件md5值
        let fileReader = new FileReader()
        let spark = new SparkMD5.ArrayBuffer()
        fileReader.readAsArrayBuffer(file)//获取文件二进制数据
        fileReader.onload = function (e) {
          spark.append(e.target.result)
          let fileMd5 = spark.end()
          resolve(fileMd5)
        };
      })
    },
    getSetInputRows(){
      return this.isEditSet?10:2;
    }
  }
}
</script>
<style scoped>
.ag-upload-content {
}

.ag-upload-img {
  width: 148px;
  height: 148px;
}

.ag-upload-tips {
  display: inline-flex;
  height: 148px;
  padding-left: 8px;
  padding-bottom: 28px;
  flex: 1;
  justify-content: center;
  align-items: center;

}

.ag-upload-tips span {
  width: 140px !important;
  word-break: normal;
  display: inline-block;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  line-height: 18px;
}

.ag-upload-content-mini .ag-upload-img {
  width: 48px;
  height: 48px;
}

.ag-upload-content-mini .el-upload-list__item {
  width: 48px;
  height: 48px;
}

.ag-upload-content-mini ::v-deep .el-upload--picture-card {
  width: 48px !important;
  height: 48px !important;
  line-height: 48px !important;
}

.ag-upload-content-mini i {
  font-size: 14px !important;
}

.ag-upload-content-mini ::v-deep .el-upload-list__item-actions span {
  margin-left: 0 !important;
  margin: 0 1px;
}


</style>
