import BaseRes from "@/ag/entities/res/BaseRes";


export default class AsyConfigRes extends BaseRes {

  projectType = [] //工程类型
  slabCategory = []//板材种类
  slabClassify = []//板材分类
  slabColor = []//板材颜色分类
  slabScene = []//板材使用场景分类
  slabVeins = []//板材纹理分类
  imageModules = [] // 模块图片
  actionTypes = [] // 动作type
  slabCrafts = [] // 工艺
  caseTypes = [] // 案例类型
  decorateCasusProjectType = [] //石材装修工程类型
  decorateCasusScene = [] //石材装修场景
  decorateCasusStyle = [] //石材装修风格
  decorateSlabScene = [] //石材装修板材适用场景
  decorateSlabType = [] //石材装修板材材料类型
  decorateSlabColor = [] //石材装修板材色系
  decorateSlabVeins = [] //石材装修板材纹理
  decorateNeedInstall = [] //石材装修订单-需要安装
  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }
}
