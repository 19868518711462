<template>
  <el-dialog
    class="--ag-dialog-container" :class="dialogStyle.fullscreen?'--ag-dialog-header-none --ag-full-dialog':'--ag-normal-dialog'"
    :visible.sync="inVisible"
    :title="dialogStyle.title"
    :center="dialogStyle.center"
    :close-on-click-modal="dialogStyle.closeOnClickModal"
    :close-on-press-escape="dialogStyle.closeOnPressEscape"
    :fullscreen="dialogStyle.fullscreen"
    :append-to-body="dialogStyle.appendToBody"
    :show-close="dialogStyle.isShowClose"
    :width="dialogStyle.width">
    <ag-dialog-body
      v-if="inVisible"
      @onDialogClose="onDialogClose"
      @onDialogAction="onDialogAction"
      :dialog="dialog"/>
  </el-dialog>
</template>
<script>

import AgDialogBody from "@/ag/components/AgDialog/body";

export default {
  name: 'AgDialog',
  components: {AgDialogBody},
  props: {
    visible: {type: Boolean, default: false},
    dialog: {
      type: Object, default: () => {
        return {dialogStyle:{}}
      }
    },
  },
  created() {
  },
  data: function () {
    return {
      inVisible: this.visible,
      dialogStyle:{
        title:'我是标题',
        width:"680px",
        center:true,
        appendToBody:false,
        fullscreen:false,
        closeOnClickModal:true,
        closeOnPressEscape:true,
        isShowClose:true,
      }
    }
  },
  watch: {
    visible(val) {
      this.inVisible = val
    },
    dialog(val) {
      this.dialog = val
      this.dialogStyle = {...this.dialogStyle, ...this.dialog.dialogStyle}
    },
    inVisible(val) {
      this.$emit('update:visible', val);
    }
  },
  methods: {
    onDialogClose() {
      this.inVisible = false
    },
    onDialogAction({data,dialog}) {
      this.$emit('onDialogAction', {data,dialog})
    }
  }
}
</script>

