import DecorationPropItemRes from "@/entities/asy/dto/cms/decoration/prop/DecorationPropItemRes";

export default class DecorationPropDetailRes extends DecorationPropItemRes {

    id = '' // id
    name = '' // 属性名称
    cover = '' // 属性封面
    max_count = '' // 选择属性值最大个数
    min_count = '' // 选择属性值最小个数
    create_time = '' // 创建时间
  
  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }
}