import {StaticRouter} from "@/router/static-router";
import UiKit from "@/ag/kits/UiKit";
import Login from "@/pages/index/views/Login/Login";
import Auth from "@/core/Auth";

export const GlobalRouter = {
  router: null,

  // 初始完成后
  onInitAfter(router) {
    this.router = router
    this.addStaticRoutes(StaticRouter)
    router.beforeEach((to, from, next) => {
      this.beforeEach(to, from, next)
    })
  },
  async beforeEach(to, from, next) {
    const {meta, name, fullPath,query} = to
    const {base} = this.router.options
    const redirect = `${base}#${fullPath}`
    console.log('redirect', to)
    try {
      // 静态路由
      if (meta && meta.static) {
        // 如果是登录页面
        if (Login.name === name) {
          // 自动登录成功，重定向到首页
          if (await Auth.tryLogin()) {
            return UiKit.navHome()
          } else {
            // 没有登录则停在登录页面
            return next()
          }
        } else {
          // 其他静态页面直接导航
          return next()
        }
      } else {
        // 非静态页面要求必须登录
        console.log('next0')
        if (await Auth.tryLogin(query)) {
          console.log('next1')
          return next()
        }
      }
    } catch (e) {
      return UiKit.nav2Login(redirect)
    }
    //
    return UiKit.nav2Login(redirect)
  },
  // 添加
  addStaticRoutes(routes) {
    for (const route of routes) {
      this.router.addRoute(route)
    }
  }
}
