<template>
  <el-cascader
    class="ag-form-address"
    :size="size"
    :placeholder="placeholder"
    :disabled="disabled"
    :clearable="clearable"
    :is-region="isRegion"
    :options="options"
    v-model="currentValue"
    @change="handleChange">
  </el-cascader>
</template>

<script>
import {CodeToText, provinceAndCityData, regionData} from 'element-china-area-data'

/**
 *  主要问题是uniapp 与 elementui 地址编码不一致问题
 *  uniapp：000044,004401,440106
 *  elementui:120000,120100,120102
 *  解决方法：在显示前、保存前都做处理
 */
export default {
  name: 'AgFormAddress',
  props: {
    value: {
      type: [String],
      default: ''
    },
    size: {
      type: [String],
      default: null
    },
    placeholder: {
      type: [String],
      default: null
    },
    disabled: {
      type: [Boolean],
      default: false
    },
    clearable: {
      type: [Boolean],
      default: false
    },
    isRegion: {
      type: [Boolean],
      default: true
    },
    isEncode: { // 是否转码，数据库保存的uniapp编码格式
      type: [Boolean],
      default: true
    }
  },
  data() {
    return {
      options: this.isRegion ? regionData : provinceAndCityData,
      currentValue: [], // 后台格式数据，用于组件线索
      currentNewValue: [] // 前台格式数据
    }
  },
  watch: {
    value(newValue) {
      // 尝试转成后端数据格式
      this.currentValue = this.isEncode ? this.wx2EleCode(newValue.split(",")) : newValue.split(",")
      console.log('this.currentValue000', this.currentValue)
      this.handleChange()
    }
  },
  methods: {
    handleChange(val) {
      // 尝试转出前端
      /**
       * currentValue 后端格式数据
       * currentNewValue 前端格式数据
       * @type {*|*[]|[]}
       */
      this.currentNewValue = this.isEncode ? this.ele2WxCode(this.currentValue) : this.currentValue
      console.log('this.currentValue', this.currentValue)
      console.log('this.currentNewValue', this.currentNewValue)
      let ids = this.currentNewValue.join(",");
      this.$emit('input', ids);

      // 获取城市名称
      let provinceCode = this.currentValue[0] ? this.currentValue[0] : '';
      let cityCode = this.currentValue[1] ? this.currentValue[1] : '';
      let areaCode = this.currentValue[2] ? this.currentValue[2] : '';

      let provinceNewCode = this.currentNewValue[0] ? this.currentNewValue[0] : '';
      let cityNewCode = this.currentNewValue[1] ? this.currentNewValue[1] : '';
      let areaNewCode = this.currentNewValue[2] ? this.currentNewValue[2] : '';

      let region = {}
      if (provinceCode) {
        let provinceStr = CodeToText[provinceCode] ? CodeToText[provinceCode] : '';
        region.province = {
          label: provinceStr,
          value: provinceNewCode
        }
      }
      if (cityCode) {
        let cityStr = CodeToText[cityCode] ? CodeToText[cityCode] : '';
        region.city = {
          label: cityStr,
          value: cityNewCode
        }
      }
      if (areaCode) {
        let areaStr = CodeToText[areaCode] ? CodeToText[areaCode] : '';
        region.area = {
          label: areaStr,
          value: areaNewCode
        }
      }
      this.$emit('change', region)
    },
    // 小程序->后台
    wx2EleCode(area) {
      // 000044,004401,440106
      // 440000,440100,440106
      let newArea = [...area] // 生成一个新对象
      for (const i in newArea) {
        const code = newArea[i]
        if (code.indexOf('0000') === 0) {
          newArea[i] = code.replace('0000', '') + '0000'
        } else if (code.indexOf('00') === 0) {
          newArea[i] = code.replace('00', '') + '00'
        }
      }
      return newArea;

    },
    ele2WxCode(area) {
      // 后台->小程序
      // 440000,440100,440106
      // 000044,004401,440106
      let newArea = [...area] // 生成一个新对象
      for (const i in newArea) {
        const code = newArea[i]
        if (code.indexOf('0000') === 2) {
          newArea[i] = '0000' + code.replace('0000', '')
        } else if (code.indexOf('00') === 4) {
          newArea[i] = '00' + code.replace('00', '')
        }
      }
      return newArea;
    }
  }
}
</script>

<style scoped>

</style>
