<template>
  <div class="root ag-match-parent ag-flex-column-center " :style="{backgroundImage:`url(${loginBkg})`}">
    <div class="ctx">
      <img src="@/assets/logo-text.png" style="height: 48px" class="ag-margin-top-60"/>
      <div style="margin-top: 240px;float: right">
        <el-card v-if="cardType===CARD_VIEWS.PHONE_LOGIN" class="login-box">
          <div class="ag-flex-column-center">
            <div class="ag-font-20 ag-color-333" style="margin-top: 40px">登录</div>
            <el-form class="login-form" ref="form" :model="form" label-width="80px" style="margin-top: 80px;">
              <el-input style="height: 48px;" placeholder="手机号码" v-model="form.phone" type="tel" clearable
                        maxlength="11"
                        @focus="setAction(ACTIONS.CHECK)">
                <el-select v-model="form.area" slot="prepend" style="width: 80px;">
                  <el-option label="+86" value="+86"></el-option>
                </el-select>
              </el-input>
              <el-input v-show="action===ACTIONS.LOGIN" v-model="form.password" class="ag-margin-top-20"
                        placeholder="请输入密码"
                        :show-password="true" clearable/>
              <div class="ag-margin-top-20"/>
              <el-button v-if="action===ACTIONS.CHECK" :disabled="!form.phone" :loading='agSubmitting'
                         class="ag-match-width"
                         type="primary"
                         @click="onNextClick">下一步
              </el-button>
              <el-button v-else-if="action===ACTIONS.LOGIN" :disabled="!form.password" :loading="agSubmitting"
                         class="ag-match-width" type="primary"
                         @click="onLoginClick">登录
              </el-button>
              <el-checkbox style="margin-top: 8px;" v-model="autoLogin">自动登录</el-checkbox>
              <el-button v-if="action===ACTIONS.LOGIN" style="float: right" type="text" @click="setCodeLoginType">验证码登录
              </el-button>
            </el-form>
          </div>
        </el-card>
        <el-card v-else-if="cardType===CARD_VIEWS.CODE_LOGIN" class="login-box ag-flex-column-center">
          <el-button type="text" @click="onBack">返回</el-button>
          <p>验证码登录</p>
        </el-card>
        <el-card v-else-if="cardType==='select'" class="login-box ag-flex-column-center">
          <el-button type="text" @click="onBack">返回</el-button>
          <p>选择组织</p>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import Index from './index'
import Card from './card'
import Redirect from "@/pages/index/views/Login/redirect";

export default {
  name: 'Login',
  mixins: [Index, Card, Redirect]
}
</script>

<style scoped lang="sass">
@import "index"
</style>
<style scoped>
>>> .el-input__inner {
  height: 48px !important;
}
>>> .el-button {
  padding: 16px 20px;
}
>>> .el-card__body{
  padding: 0px;
}
</style>
