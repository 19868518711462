<template>
  <div class="ag-dialog-normal">
    <div class="ag-dialog-body">
      <el-row class="wrap-container">
        <el-col :span="12" class="left-selector-panel">
          <el-row class="title">选择:</el-row>
          <el-row class="content">
            <el-row class="search">
              <el-input
                size="small"
                placeholder="搜索"
                prefix-icon="el-icon-search"
                v-model="searchText">
              </el-input>
            </el-row>
            <el-row class="list-panel">
              <el-row class="item breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item v-for="breadcrumb in breadcrumbs" @click.native.stop="getTopArchitecture(breadcrumb.id)" :key="breadcrumb.id">{{breadcrumb.title}}</el-breadcrumb-item>
                </el-breadcrumb>
              </el-row>
              <el-row>
                <el-checkbox v-if="isMultiple && employeeList.length>0" class="item" v-model="checkedAll" @change="checkAllItem">全选</el-checkbox>
              </el-row>
              <!---渲染员工--->
              <el-row class="item" v-for="item in employeeList" :key="item.id">
                <el-col
                  :span="24"
                  class="name"
                  @click.native.stop="selectEmployee(item)"
                  :title="item.name">
                  <el-checkbox
                    v-if="isMultiple"
                    @change="selectEmployee(item)"
                    v-model="item.checked">
                  </el-checkbox>
                  {{item.name}}
                </el-col>
              </el-row>
              <!---渲染部门--->
              <el-row class="item" v-for="item in architectureList" :key="'a'+item.id" @click.native.stop="getTopArchitecture(item.id)">
                <el-col
                  :span="18"
                  class="name"
                  :title="item.title">
                  {{item.title}}
                </el-col>
                <el-col :span="6" class="operate">下级</el-col>
              </el-row>
            </el-row>
          </el-row>
        </el-col>
        <el-col :span="12" class="right-selector-panel">
          <el-row class="title">已选:</el-row>
          <el-row class="content">
            <el-row class="list-panel">
              <el-row class="item" v-for="employee in selectEmployeeData" :key="employee.id">
                <el-col :span="18" class="name" :title="employee.name">{{employee.name}}</el-col>
                <el-col :span="6" class="operate"><i class="el-icon-circle-close" @click="cancelSelect(employee.id)"></i></el-col>
              </el-row>
            </el-row>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="ag-dialog-footer">
      <span>
        <el-button @click="onConfirmAction" type="primary">确定</el-button>
        <el-button @click="agDialogClose">取消</el-button>
      </span>
    </div>
  </div>
</template>

<script>
import Index from './index'

export default {
  name: 'SelectEmployee',
  mixins:[Index]
}
</script>

<style scoped lang="sass">
@import "index"
</style>
