import BaseRes from "@/ag/entities/res/BaseRes";
import {getValidateByKey} from "@/ag/checker";

export class BaseDto extends BaseRes {
  // 用于UI view object
  $vo = {
    architecture: [1]
  }
  // 规则验证
  $rules = {}

  /**
   * 转成服务器需要的字段 （其实就是删除$vo、rules对象）
   * @returns {{}}
   */
  toDto() {
    let dto = {}
    const ignoreProps = this.ignoreProps()
    for (const key in this) {
      if (this.hasOwnProperty(key) && !ignoreProps.includes(key)) {
        dto[key] = this[key];
      }
    }
    return dto;
  }

  /**
   * 忽略的字段
   * @returns {string[]}
   */
  ignoreProps() {
    return ['$vo', '$rules']
  }

  // 验证
  required(message = '必填') {
    return {required: true, message}
  }

  toValidateByKey(params) {
    return getValidateByKey(true, ['phone'])
  }
}
