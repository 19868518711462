import {BasePageMixin} from "@/mixins/BasePageMixin";
import NoticeDetailRes from "@/entities/applets/res/notice/NoticeDetailRes";
import NoticeApi from "@/api/applets/notice/NoticeApi";
import {DrawerMixin} from "@/ag/mixins/components/DrawerMixin";


export default {
  mixins: [BasePageMixin,DrawerMixin],
  props: {},
  data() {
    return {
      detail:new NoticeDetailRes()
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      const {id} = this.agGetPreload()
      NoticeApi.detail(id).exec().then((res) => {
        this.detail = res
      })
    },
  }
}
