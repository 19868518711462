import UiKit from "@/ag/kits/UiKit";
import BaseApi from "@/ag/api/BaseApi";
import CacheKit from "@/ag/kits/CacheKit";
import {CacheKey} from "@/consts/CacheKey";
import {Env} from "@/consts/Env";

export default class SwBaseApi extends BaseApi {

  constructor(url, method = 'get', isShowErr = true) {
    super(url, method, isShowErr);
  }

  _getBaseUrl() {
    return Env.getApi('sw')
  }

  _showErr(err) {
    UiKit.error(err)
  }

  // 子类重载
  _getHeaders() {
    const token = CacheKit.get(CacheKey.TOKEN)
    if (token) {
      return {'ag-token': token}
    }
    return {}
  }
}
