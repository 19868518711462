<template>
  <el-image fit="scale-down" :style="divStyle" :src="src" lazy :preview-src-list="toArray(src)" />
</template>

<script>
export default {
  name: 'AgTableColumnImage',
  props: {
    // 图片链接
    src: { type: String, default: '' },
    divStyle: {
      type: String,
      default: 'height: 38px'
    }
  },
  methods: {
    toArray(obj) {
      return [obj]
    }
  }
}
</script>

<style scoped>

</style>
