
import { DrawerMixin } from '@/ag/mixins/components/DrawerMixin'
import { FormMixin } from '@/ag/mixins/FormMixin'
import { DialogPageMixin } from '@/mixins/DialogPageMixin'
import NoticeDto from "@/entities/applets/dto/notice/NoticeDto";
import {APPLETS_TYPE, NOTICE_ACTIONS} from "@/pages/applets/views/notice/Notice/macros";
import NoticeApi from "@/api/applets/notice/NoticeApi";
import UiKit from "@/ag/kits/UiKit";
import {BasePageMixin} from "@/mixins/BasePageMixin";

export default {
  mixins: [DrawerMixin, FormMixin, DialogPageMixin,BasePageMixin],
  props: {},
  data() {
    return {
      agForm: new NoticeDto(),
      id : ''
    }
  },
  created() {
    let {id} = this.agGetPreload()
    this.id = id
    if (this.agAction === NOTICE_ACTIONS.NOTICE_EDIT){
      this.agIsEdit = true
      this.getDetail()
    }
  },
  computed: {
    appTypes() {
      return APPLETS_TYPE;
    }
  },
  methods: {
    getDetail(){
      NoticeApi.detail(this.id).setOnLoadingChange(this.onAgSubmitChange.bind(this)).exec().then((res)=>{
        this.agForm = new NoticeDto(res)
      })
    },
    onSubmit(){
      this.agValidate().then(()=>{
        if (this.agAction === NOTICE_ACTIONS.NOTICE_EDIT){
          NoticeApi.update(this.agForm.toDto()).setOnLoadingChange(this.onAgSubmitChange.bind(this)).exec().then((res)=>{
            this.agDrawerDispatchAction()
            this.agDrawerClose()
            UiKit.success('保存成功')
          })
        }else{
          NoticeApi.create(this.agForm.toDto()).setOnLoadingChange(this.onAgSubmitChange.bind(this)).exec().then((res)=>{
            this.agDrawerDispatchAction()
            this.agDrawerClose()
            UiKit.success('添加成功')
          })
        }
      })
    }
  }
}
