// Any plugins you want to use has to be imported
// Detail plugins list see https://www.tinymce.com/docs/plugins/
// Custom builds see https://www.tinymce.com/download/custom-builds/

const styles = {
  style_formats: [
    {
      title: "行高", items: [
        {title: '1', block: 'p', styles: {'line-height': '1.0'}},
        {title: '1.5', block: 'p', styles: {'line-height': '1.5'}},
        {title: '1.75', block: 'p', styles: {'line-height': '1.75'}},
        {title: '2', block: 'p', styles: {'line-height': '2'}},
        {title: '3', block: 'p', styles: {'line-height': '3'}},
        {title: '4', block: 'p', styles: {'line-height': '4'}},
        {title: '5', block: 'p', styles: {'line-height': '5'}},
      ]
    },
  ],
  style_formats_merge: true,
  style_formats_autohide: true,
}

export default styles
