import Login from "@/pages/index/views/Login/Login";
import Logout from "@/pages/index/views/Logout/Logout";
import Page404 from "@/pages/index/views/Page404/Page404";
import Body from "@/components/layout/Body";

export const StaticRouter = [
  {
    path: '/',
    component: Body,
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: Login.name,
        component: Login,
        meta: {static: true}
      },
      {
        path: '/logout',
        name: Logout.name,
        component: Logout,
        meta: {static: true}
      },
    ]
  },
  {
    path: '/*',
    name: Page404.name,
    component: Page404,
    meta: {static: true}
  }
]
