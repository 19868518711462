import ParamsItemRes from "@/entities/applets/res/params/ParamsItemRes";

export default class ParamsDetailRes extends ParamsItemRes {

      id = '' // 自增id
      mini_id = '' // 关联小程序id
      key = '' // 键名
      desc = '' // 描述
      create_time = '' // 创建时间
  
  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }
}