import BaseRes from '@/ag/entities/res/BaseRes'
import {toClassArray} from "@/ag/utils";

export default class AuthRuleItemRes extends BaseRes{

  id = '' // id
  pid = '' // 父ID
  title = '' // 规则名称
  icon = '' // 图标
  logo = '' // logo
  show_type = '' // 显示隐藏 显示隐藏0全部1前端2后端3全部不
  app_type = '' // 规则类型  规则类型0分组1父app 2子app 3action
  web_url = '' // 后台链接
  h5_url = '' // 前端链接
  api_url = '' // api接口链接
  weigh = '' // 权重

  children = []

  isShow = false
  hasChildren = false

  constructor(data) {
    super();
    this.initProp(data)
  }
  onAfterSetProp() {
    this.children = toClassArray(this.children,AuthRuleItemRes)

    this.isShow = [0,2].includes(this.show_type) && ![3].includes(this.app_type)
    const childrens = this.children.filter((item)=>item.isShow==true)
    this.hasChildren = childrens.length>0
  }
}
