
import { DrawerMixin } from '@/ag/mixins/components/DrawerMixin'
import { FormMixin } from '@/ag/mixins/FormMixin'
import { DialogPageMixin } from '@/mixins/DialogPageMixin'
import ProductSpecsTypeDto from "@/entities/asy/dto/products/specsType/ProductSpecsTypeDto";
import { PRODUCT_SPECS_TYPE_ACTIONS } from "@/pages/asy/views/products/specsType/ProductSpecsType/macros";
import ProductSpecsTypeApi from "@/api/asy/products/specsType/ProductSpecsTypeApi";
import UiKit from "@/ag/kits/UiKit";
import {BasePageMixin} from "@/mixins/BasePageMixin";

export default {
  mixins: [DrawerMixin, FormMixin, DialogPageMixin,BasePageMixin],
  props: {},
  data() {
    return {
      agForm: new ProductSpecsTypeDto(),
      id : '',
      classId : ''
    }
  },
  created() {
    let {id,classId} = this.agGetPreload()
    this.id = id
    this.classId = classId
    this.agForm.class_id = this.classId
    if (this.agAction === PRODUCT_SPECS_TYPE_ACTIONS.PRODUCT_SPECS_TYPE_EDIT){
      this.agIsEdit = true
      this.getDetail()
    }
  },
  methods: {
    getDetail(){
      ProductSpecsTypeApi.detail(this.id).setOnLoadingChange(this.onAgSubmitChange.bind(this)).exec().then((res)=>{
        this.agForm = new ProductSpecsTypeDto(res)
      })
    },
    onSubmit(){
      this.agValidate().then(()=>{
        if (this.agAction === PRODUCT_SPECS_TYPE_ACTIONS.PRODUCT_SPECS_TYPE_EDIT){
          ProductSpecsTypeApi.update(this.agForm.toDto()).setOnLoadingChange(this.onAgSubmitChange.bind(this)).exec().then((res)=>{
            this.agDrawerDispatchAction()
            this.agDrawerClose()
            UiKit.success('保存成功')
          })
        }else{
          ProductSpecsTypeApi.create(this.agForm.toDto()).setOnLoadingChange(this.onAgSubmitChange.bind(this)).exec().then((res)=>{
            this.agDrawerDispatchAction()
            this.agDrawerClose()
            UiKit.success('添加成功')
          })
        }
      })
    }
  }
}
