<template>
  <el-image class="image" fit="contain" :src="src" :style="{width:size+'px',height:size+'px'}" :preview-src-list="previewImages" ></el-image>
</template>

<script>

import {getKeyValuesByArray} from "@/ag/utils";

export default {
  name: 'AgDetailImage',
  props: {
    src: {
      type: [String],
      default: ''
    },
    size: {
      type: [Number],
      default: 180
    },
    previewUrls: {
      type: [Array],
      default(){return []}
    },
    arrayKey: {
      type: [String],
      default:null,
    },
  },
  computed:{
    previewImages(){
      if (this.arrayKey){
        return getKeyValuesByArray(this.previewUrls,this.arrayKey)
      }else{
        return this.previewUrls;
      }

    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped>
.image{
  margin-right: 20px;
  margin-bottom: 20px;
}

</style>
