<template>
  <el-select :size="size" :disabled="disabled" :multiple="multiple" v-model="currentValue" :placeholder="placeholder" :clearable="clearable" class="ag-form-select" @change="handleModelInput">
    <el-option v-for="(item,i) in selectList" :key="item.value" :label="item[props.label]" :value="item[props.value]"/>
  </el-select>
</template>

<script>

export default {
  name: 'AgFormSelect',
  props: {
    props:{
      type: [Object],
      default(){
        return {
          label:'label',
          value:'value',
        }
      }
    },
    multiple: {
      type: [Boolean],
      default: false
    },
    clearable: {
      type: [Boolean],
      default: false
    },
    placeholder: {
      type: [String],
      default: null
    },
    size: {
      type: [String],
      default: 'medium'
    },
    selectList: {
      type: [Object,Array],
      default: null
    },
    value: {
      type: [Number, String,Array],
      default: null
    },
    disabled: {
      type: [Boolean],
      default: false
    }
  },
  data() {
    return {
      currentValue: this.value
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue
    }
  },
  methods: {
    handleModelInput(event) {
      this.$emit('input', this.currentValue)
      this.$emit('change', this.currentValue)
    },
    toKey(key) {
      if (this.keyIsNum) {
        return Number.parseInt(key)
      }else{
        return key + '';
      }

    }
  }
}
</script>

<style scoped>

</style>
