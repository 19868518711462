const RULES_CONFIG = {
  notnull: {
    errMessage: '必填！'
  },
  phone: {
    reg: /^1[3456789]\d{9}$/,
    errMessage: '手机号不正确！'
  },
}

export function getValidateByKey(isRequired = false, rulesKey = []) {
  return {
    validator: (rule, value, callback) => {
      // 必填
      if (isRequired && !value) {
        callback(new Error(RULES_CONFIG.notnull.errMessage));
      }
      //使用正则表达式进行验证
      if (rulesKey.length > 0) {
        for (const key of rulesKey) {
          if (!RULES_CONFIG[key].reg.test(value)) {
            callback(new Error(RULES_CONFIG[key].errMessage));
          }
        }
      }
      callback()
    },
    required: isRequired
  }
}
