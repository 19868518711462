<template>
  <el-input
    class="filter-item"
    v-model="currentValue"
    :size="size"
    :placeholder="placeholder"
    :clearable="clearable"
    :style="{width:width}"
    :type="type"
    @input="handleModelInput"/>
</template>

<script>

export default {
  name: 'AgTableFilterInput',
  props: {
    placeholder: {
      type: [String],
      default: null
    },
    clearable: {
      type: [Boolean],
      default: true
    },
    size: {
      type: [String],
      default: 'medium'
    },
    width: {
      type: [String],
      default: "120px"
    },
    type: {
      type: [String],
      default: "text"
    },
    value: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      currentValue: this.value
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue
    }
  },
  methods: {
    handleModelInput(event) {
      this.$emit('input', this.currentValue)
    }
  }
}
</script>

<style scoped>

</style>
