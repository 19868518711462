import BaseRes from '@/ag/entities/res/BaseRes'

export default class DecorationPropAndItemsRes extends BaseRes{

  id = '' // id
  name = '' // name
  cover = '' // 属性封面
  is_multiple = '' // 是否多选
  max_count = '' // 选择属性值最大个数
  min_count = '' // 选择属性值最小个数
  items = []

  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
    if (this.is_multiple) {
      this.tips = this.name+` (${this.max_count}个)`
    } else {
      this.tips = this.name+' (单选)'
    }

    this.resetSelect()

  }
  resetSelect(){
    if (this.isMultiple){
      this.select = []
    }else{
      this.select = null
    }
  }
  getSelectIds(){
    if (this.isMultiple){
      return this.select
    }else{
      if (this.select){
        return [this.select]
      }
    }
    return []
  }
  setSelectIds(properties){
    this.resetSelect()
    console.log(properties)
    let itemIds = this.items.map((item)=>{
      return item.value
    })
    for (const itemId of itemIds) {
      if(properties.includes(itemId)){
        if (!this.isMultiple){
          this.select = itemId
          break
        }else{
          this.select.push(itemId)
        }
      }
    }
  }
}
