export const BaseMixin = {
  methods: {
    // 直接导航连接
    agNavUrl(url) {
      window.location.href = url
    },
    // 打开
    agOpenUrl(url) {
      window.open(url)
    }
  }
}
