import PagesForm from "@/pages/applets/views/pages/Pages/components/drawers/PagesForm/PagesForm";
import PagesDetail from "@/pages/applets/views/pages/Pages/components/drawers/PagesDetail/PagesDetail";

export const PAGES_ACTIONS = {
  PAGES_ADD: 'PAGES_ADD',
  PAGES_EDIT: 'PAGES_EDIT',
  PAGES_DETAIL: 'PAGES_DETAIL',
}
export const PAGES_DRAWERS = {
  PAGES_ADD: {
    action: PAGES_ACTIONS.PAGES_ADD,
    component: PagesForm,
    preload: {title: '添加'},
    drawerStyle: {
      drawerClass: 'ag-drawer-mid-class',
      appendToBody:true
    }
  },
  PAGES_EDIT: {
    action: PAGES_ACTIONS.PAGES_EDIT,
    component: PagesForm,
    preload: {title: '编辑'},
    drawerStyle: {
      drawerClass: 'ag-drawer-mid-class',
      appendToBody:true
    }
  },
  PAGES_DETAIL: {
    action: PAGES_ACTIONS.PAGES_DETAIL,
    component: PagesDetail,
    preload: {title: '详情'},
    drawerStyle: {
      drawerClass: 'ag-drawer-mid-class',
      appendToBody:true
    }
  }
}
