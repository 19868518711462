import EmployeeBaseRes from '@/entities/oa/res/employee/EmployeeBaseRes'

export default class EmployeeDetailRes extends EmployeeBaseRes{

  phone = '' // 手机号码
  email = '' // 邮箱
  tel = '' // 分机号
  directly_manager = '' // 直属主管
  job_title = '' // 职位
  number = '' // 员工编号
  workplace = '' // 工作地点
  entry_time = '' // 入职时间
  remark = '' // 备注
  create_time = '' // 创建时间
  architectures = [] // 关联部门
  roles = [] // 关联角色

  constructor(data) {
    super();
    this.initProp(data)
  }
  onAfterSetProp() {
  }
}
