/**
 * 列表页面
 */
import {BasePageMixin} from "@/mixins/BasePageMixin";
import {deepClone} from "@/ag/utils";
import UiKit from "@/ag/kits/UiKit";

export const ListPageMixin = {
  mixins: [BasePageMixin],
  data() {
    return {
      // 子类设置
      agPaging: undefined, // 分页器
      agUpdateApi: undefined,// 更新APi
      // 搜索 {id:1,name:'马'}
      agFilterQuery: {},
      // 排序  [{key:'status',sort:'asc'}]
      agSort: [],
      // 分页信息
      agPageQuery: {
        current_page: 1, // 当前哪一页
        per_page: 10 // 每一页行数
      },
      // 搜索条件规则 {id:':',name:'LIKE',create_time:''}
      agFilterOption: {},

      agTableKey: 1,
      agListLoading: false,
      agListData: [], // 列表数据
      agSelections: [],// 选中
      agTotal: 0, // 总数,
      isAgAutoLoad: true,
    }
  },
  created() {
    this.$nextTick(() => {
      // 等子mixin初始化好，备份一下数据，用于重置
      this._clone()
      if (this.isAgAutoLoad) {
        this.agRefresh(true)
      }
    })
  },
  methods: {

    /**
     * 刷新
     */
    agRefresh(refresh = false) {
      this.agGetPaging()
        .setOnLoadingChange(this.agShowPageLoading.bind(this))
        .addData(this._getPageParams(refresh)).exec().then((res) => {
        this._handleData(res)
        if (this.onAgListAfter) {
          this.onAgListAfter(res)
        }
      })
    },
    /**
     * 搜索
     * @param delay 是否延迟
     */
    onAgSearch(delay = false) {
      if (!delay) {
        this.agRefresh()
      } else {
        this._delaySearch()
      }
    },
    /**
     * 重置搜索
     */
    onAgSearchReset() {
      this._reset()
      this.agRefresh(true)
    },

    /**
     *  分页变化
     */
    onAgPageChange() {
      this.agRefresh()
    },
    /**
     * 加载中状态变化
     * @param loading
     */
    agShowPageLoading(loading) {
      this.agListLoading = loading
    },
    /**
     * 延迟获取分页器，子类可以重载
     * @returns {*}
     */
    agGetPaging() {
      if (!this.agPaging) {
        console.error("请设置agGetPaging, 例如：this.paging = xxxx")
      }
      return this.agPaging;
    },

    /**
     * 数据加载完回调，子类可以重载
     */
    onAgListAfter() {
    },
    /**
     * 更新数据
     * @param id
     * @param data
     * @param msg
     */
    onAgUpdateRowById(id = null, data = {}, msg = '操作成功') {
      if (!this.agUpdateApi) {
        console.error('onAgUpdateRowById', 'agUpdateApi未设置')
      }
      if (!id) {
        console.error('onAgUpdateRowById', 'id不能为空')
      }
      data.id = id
      this.agUpdateApi.setData(data)
      this.agUpdateApi.exec().then(() => {
        UiKit.success(msg)
        this.agRefresh()
      })
    }

    ,
    // 表格相关处理
    /**
     * 排序变化
     * @param column
     */
    agTableHeaderClass({column}) {
      const {property, sortable} = column
      if (!sortable) {
        return
      }
      const sortQuery = this.agSort
      const find = sortQuery.find((item) => {
        return item.key === property
      })
      if (!find) {
        column.order = undefined
        return
      }
      column.order = find.sort === 'desc' ? 'descending' : 'ascending'
    },
    /**
     * el_table 给某row附加数据
     * @param row
     * @param rowIndex
     */
    agTableRowClassName({row, rowIndex}) {
      row._ag_index = rowIndex
    },
    /**
     * 选中
     * @param selection
     */
    onAgTableSelectionChange(selection) {
      this.agSelections = selection
    },
    /**
     * 排序
     * @param column
     * @param prop
     * @param order
     */
    onAgTableSorChange({column, prop, order}) {
      column.order = order
      this._handleSort(prop, order)
    },


    /**
     * 备份数据
     * @private
     */
    _clone() {
      this._agFilterQuery = deepClone(this.agFilterQuery)
      this._agSort = deepClone(this.agSort)
      this._agPageQuery = deepClone(this.agPageQuery)
      this._agFilterOption = deepClone(this.agFilterOption)
    },
    /**
     * 重置数据
     * @private
     */
    _reset() {
      this.agFilterQuery = deepClone(this._agFilterQuery)
      this.agSort = deepClone(this._agSort)
      this.agPageQuery = deepClone(this._agPageQuery)
      this.agFilterOption = deepClone(this._agFilterOption)
    },
    /**
     * 延迟搜索
     * @private
     */
    _delaySearch() {
      if (this._delaySearchTimer) {
        clearTimeout(this._delaySearchTimer)
      }
      this._delaySearchTimer = setTimeout(() => {
        this.onAgSearch()
      }, 600)
    },
    /**
     * 处理排序
     * @param key
     * @param sort
     * @private
     */
    _handleSort(key, sort) {
      this.agSort = [];
      const sortQuery = this.agSort
      let findIndex = null
      let find = null
      for (let i = 0; i < sortQuery.length; i++) {
        const item = sortQuery[i]
        if (key === item.key) {
          findIndex = i
          find = item
        }
      }
      // 处理点击header也会触发这个函数问题，就默认倒序
      if (!find && !sort) {
        return
      }
      // 如果是相同的排序直接返回
      if (find && find.sort === sort) {
        return
      }
      if (find) {
        sortQuery.splice(findIndex, 1)
      }
      if (sort) {
        const newItem = {
          key
        }
        newItem.sort = (sort === 'descending' ? 'desc' : 'asc')
        sortQuery.push(newItem)
      }
      this.agRefresh()
    },

    /**
     * 请求参数
     * @param refresh
     * @returns {{filter: *[], op: *[], page: *[], sort: *[]}}
     * @private
     */
    _getPageParams(refresh = false) {
      let page = null
      if (refresh) {
        page = deepClone(this._agPageQuery)
      } else {
        page = deepClone(this.agPageQuery)
      }
      let op = deepClone(this.agFilterOption)
      const sort = deepClone(this.agSort)
      let filterQuery = deepClone(this.agFilterQuery)
      for (const key in filterQuery) {
        if (filterQuery[key] === '' || filterQuery[key] == null) {
          delete filterQuery[key];
        }
      }
      // 回调一下给前端做子类
      if (this.agBeforeFilterQuery) {
        filterQuery = this.agBeforeFilterQuery(filterQuery)
      }
      return {filter: filterQuery, page, sort, op}
    },

    // 处理数据
    _handleData(data) {
      if (data) {
        let {list, total} = data;
        this.agTotal = total
        this.agListData = list
      }
    },
    agBeforeFilterQuery(filterQuery) {
      return filterQuery
    }
  }
}

