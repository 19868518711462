import AsyBaseApi from "@/api/asy/AsyBaseApi";
import PagingKit from "@/ag/kits/PagingKit";
import DecorationPropItemRes from "@/entities/asy/dto/cms/decoration/prop/DecorationPropItemRes";
import DecorationPropDetailRes from "@/entities/asy/dto/cms/decoration/prop/DecorationPropDetailRes";
import DecorationPropAndItemsRes from '@/entities/asy/dto/cms/decoration/prop/DecorationPropAndItemsRes'

export default class DecorationPropApi {
  /**
   * 属性分页
   * @param data
   * @returns { AsyBaseApi }
   */
  static page(data = {}) {
    return new AsyBaseApi('/decoration/property/page', 'post')
      .setData(data)
      .setPageItemClassName(DecorationPropItemRes)
  }

  /**
   * 获取分页
   * @param data
   * @returns {PagingKit}
   */
  static paging(data) {
    return new PagingKit(DecorationPropApi.page(data), 10)
  }

  /**
   * 获取详情
   * @param id
   * @returns { AsyBaseApi }
   */
  static detail(id) {
    return new AsyBaseApi('/decoration/property/detail', 'get')
      .setParams({id})
      .setClassName(DecorationPropDetailRes)
  }

  /**
   * 获取所有的属性和属性值
   * @param type 0全部1案例2板材
   * @returns { AsyBaseApi }
   */
  static AllPropertyAndItems(type) {
    return new AsyBaseApi('/decoration/property/all_property_and_items', 'get')
      .setParams({type})
      .setListItemClassName(DecorationPropAndItemsRes)
  }

  /**
  * 保存
  * @param data
   * @returns { AsyBaseApi }
   */
  static update(data) {
    return new AsyBaseApi('/decoration/property/update', 'post')
      .setData(data)
      .setClassName(DecorationPropDetailRes)
  }

  /**
   * 创建
   * @param data
   * @returns { AsyBaseApi }
   */
  static create(data) {
    return new AsyBaseApi('/decoration/property/create', 'post')
      .setData(data)
      .setClassName(DecorationPropDetailRes)
  }

  /**
   * 删除
   * @param ids
   * @returns { AsyBaseApi }
   */
  static remove(ids) {
    return new AsyBaseApi('/decoration/property/remove', 'post')
      .setData({ids})
  }
}
