import {DrawerPageMixin} from "@/mixins/DrawerPageMixin";
import {ListPageMixin} from "@/mixins/ListPageMixin";
import ParamsApi from "@/api/applets/params/ParamsApi";
import { PARAMS_ACTIONS, PARAMS_DRAWERS } from "@/pages/applets/views/params/Params/macros";
import UiKit from "@/ag/kits/UiKit";
import {getKeyValuesByArray} from "@/ag/utils";
import {DrawerMixin} from "@/ag/mixins/components/DrawerMixin";

export default {
  mixins: [ListPageMixin,DrawerPageMixin,DrawerMixin],
  props: {},
  data() {
    return {
      agAuthMapper: {
        add: '/miniProgram/miniParams/create',
        edit: '/miniProgram/miniParams/update',
        remove: '/miniProgram/miniParams/remove',
        detail: '/miniProgram/miniParams/detail',
        page: '/miniProgram/miniParams/page',
      },
      agPaging :ParamsApi.paging(),
      agUpdateApi :ParamsApi.update(),
      agSort:[{key:'create_time',sort:'desc'}],
      isAgAutoLoad:false
    }
  },
  created() {
    this.agFilterQuery.mini_id = this.drawer.preload.id
    this.agDrawerBindAction(PARAMS_ACTIONS.PARAMS_ADD, this.onActionAdd)
    this.agDrawerBindAction(PARAMS_ACTIONS.PARAMS_EDIT, this.onActionEdit)
    this.agRefresh(true)

  },
  methods: {
    onAddClick() {
      this.agCreateDrawer(PARAMS_DRAWERS.PARAMS_ADD, {id:'',mini_id:this.drawer.preload.id})
    },
    onEditClick({id}) {
      this.agCreateDrawer(PARAMS_DRAWERS.PARAMS_EDIT, {id})
    },
    onDetailClick({id}){
      this.agCreateDrawer(PARAMS_DRAWERS.PARAMS_DETAIL, {id,detail:true})
    },
    onDelClick({id,key }) {
      UiKit.confirm(`确定要删除 ${ key } ?`,'提示').then(()=>{
        ParamsApi.remove([id]).exec().then(()=>{
          UiKit.success('删除成功')
          this.agRefresh()
        })
      })
    },
    onMultiDelClick(){
      let ids = getKeyValuesByArray(this.agSelections,'id')
      UiKit.confirm(`确定要删除这 ${ids.length} 项吗?`,'提示').then(()=>{
        ParamsApi.remove(ids).exec().then(()=>{
          UiKit.success('删除成功')
          this.agRefresh()
        })
      })
    },
    onActionAdd({drawer,data}) {
      this.agRefresh();
    },
    onActionEdit({drawer,data}) {
      this.agRefresh();
    },
  }
}
