import SelectEmployee from '@/components/oa/SelectEmployee/SelectEmployee'

export const SELECT_EMPLOYEE_ACTIONS = {
  SELECT_EMPLOYEE: 'SELECT_EMPLOYEE'
}
export const SELECT_EMPLOYEE_DIALOGS = {
  SINGLE: {
    action: SELECT_EMPLOYEE_ACTIONS.SELECT_EMPLOYEE,
    component: SelectEmployee,
    preload: {type: 'single', ids:[], architectureId:null},
    dialogStyle: {
      title: '选择员工',
    }
  },
  MULTI: {
    action: SELECT_EMPLOYEE_ACTIONS.SELECT_EMPLOYEE,
    component: SelectEmployee,
    preload: {type: 'multi', ids:[], architectureId:null},
    dialogStyle: {
      title: '选择员工',
    }
  },
}
