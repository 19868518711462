const axios = require('axios').default

/**
 * 浅复制
 * @param target
 * @param from
 * @returns {*}
 */
export function shallowClone(target, from) {
  for (const key in from) {
    if (from.hasOwnProperty(key)) {
      target[key] = from[key];
    }
  }
  return target;
}

/**
 * 深度克隆
 * @param source
 * @returns {*[]}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] instanceof Date) {
      targetObj[keys] = new Date(source[keys])
    } else if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}


/**
 * json 2 class
 * @param data
 * @param className
 * @returns {*}
 */
export function toClass(data, className) {
  if (!data) {
    return data
  }
  return new className(data)
}

/**
 * json 2 class array
 * @param array
 * @param className
 * @returns {*[]}
 */
export function toClassArray(array, className) {
  if (typeof array === 'object') {
    let newArray = []
    for (const i in array) {
      newArray.push(new className(array[i]))
    }
    return newArray
  } else {
    return []
  }
}

/**
 * 获取对象数组模块key的值
 * @param array
 * @param key
 * @returns {*[]}
 */
export function getKeyValuesByArray(array, key) {
  if (!array) {
    return []
  }
  let rets = []
  for (const item of array) {
    rets.push(item[key])
  }
  return rets
}

/**
 * 附加url参数
 * @param url
 * @param params
 * @returns {string}
 */
export function appendUrlParams(url, params = {}) {
  let paramsArray = [];
  Object.keys(params).forEach((key) => {
    let value = params[key];
    // 如果值为undefined置空
    if (typeof value === 'undefined') {
      value = '';
    }
    //使用encodeURIComponent进行编码
    if (Array.isArray(params[key])) { //类型为数组的时候
      value.forEach(item => {
        paramsArray.push([key, item].join('='));
      });
    }
    if (Object.prototype.toString.call(params[key]) === '[object Object]') { //类型为对象的时候
      Object.keys(params[key]).forEach(item => {
        paramsArray.push([key, params[key][item]].join('='));
      })
    } else {
      paramsArray.push([key, value].join('='));
    }
  });
  let paramsStr = paramsArray.join('&');
  if (url.indexOf('?') == -1) {
    return url + '?' + paramsStr;
  } else {
    return url + '&' + paramsStr;
  }
}

/**
 * 返回 页面路径
 * @param url
 * @returns {string|*}
 */
export function pagePath(url) {
  if (!url) {
    return url
  }
  if (url.substr(0, 1) !== '/') {
    return '/' + url
  }
  return url
}

/**
 * 图片下载
 * @param imageUrl
 * @param name
 */
export const downloadImage = (imageUrl, name, ex = 'jpg') => {
  axios.get(imageUrl, {responseType: 'blob'}).then((res) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `${name}.${ex}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  });

};

/**
 * 获取对象数组符合某个值的对象
 * @param key
 * @param val
 * @param list
 * @returns {{}}
 */
export function getObjFromObjArray(key, val, list = []) {
  let obj = {};
  list.forEach(item => {
    if (item[key] === val) {
      obj = item
    }
  })
  return obj
}

/**
 *
 * @param price
 * @returns {number}
 */
export function percent10(price) {
  if (typeof price !== 'number') {
    price = Number(price)
  }
  return ((price * 100) * 0.1) / 100
}

export function isArray(value) {
  return Object.prototype.toString.call(value) === '[object Array]'
}

/**
 * 生成筛选操作
 * @param fields
 * @param op
 * @returns {{}}
 */
export function buildOps(fields, op = 'LIKE') {
  let ops = {}
  for (const field of fields) {
    ops[field] = op
  }
  return ops
}
