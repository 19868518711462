import ArchitectureItemRes from "@/entities/oa/res/architecture/ArchitectureItemRes";

/**
 * 根部部门
 */

export default class ArchitectureTopRes extends ArchitectureItemRes {
  architectures = [] //子部门列表
  employees = [] //员工列表

  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }
}
