import BaseRes from "@/ag/entities/res/BaseRes";

export default class NoticeItemRes extends BaseRes {

      id = '' // 自增id
      gh_id = '' // 原始id
      app_id = '' // app_id
      name = '' // 名称
      app_key = '' // app_key
      app_secret = '' // app_secret
      create_time = '' // 创建时间
  
  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }
}