import {BaseMixin} from "@/ag/mixins/BaseMixin";
import SwCommonApi from "@/api/sw/SwCommonApi";
import {mapState} from "vuex";
import {COMMIT_ACTIONS} from "@/store/types";
import OaCommonApi from "@/api/oa/OaCommonApi";
import AsyCommonApi from "@/api/asy/AsyCommonApi";
import {AuthPageMixin} from "@/mixins/AuthPageMixin";
import AdCommonApi from "@/api/ad/AdCommonApi";
import MerchantCommonApi from "@/api/merchant/MerchantCommonApi";

export const BasePageMixin = {
  mixins: [BaseMixin,AuthPageMixin],
  computed: {
    ...mapState([
      "global",
      "assets",
      'userInfo',
      'oaConfig',
      'asyConfig',
      'swConfig',
      'adConfig',
      'merchantConfig',
    ]),
  },
  created() {
    this.assertOaConfig()
  },
  methods: {

    // 断言vuex数据存在
    assertStores(stores = ['oaConfig']) {
      for (const config of stores) {
        let temp = this.$store.state[config]
        if (config == 'oaConfig'){
          temp = this.$store.state.global[config]
        }
        if (!temp || Object.keys(temp).length < 1) {
          if (config == 'oaConfig') {
            OaCommonApi.getConfig().exec().then((res) => {
              let oaConfig = res
              this.$store.commit(COMMIT_ACTIONS.SET_OA_CONFIG, oaConfig)
            })
          }
          else if (config == 'swConfig') {
            SwCommonApi.getConfig().exec().then((res) => {
              let swConfig = res
              this.$store.commit(COMMIT_ACTIONS.SET_SW_CONFIG, swConfig)
            })
          }
          else if (config == 'asyConfig') {
            AsyCommonApi.getConfig().exec().then((res) => {
              let asyConfig = res
              this.$store.commit(COMMIT_ACTIONS.SET_ASY_CONFIG, asyConfig)
            })
          }
          else if (config == 'adConfig') {
            AdCommonApi.getConfig().exec().then((res) => {
              let adConfig = res
              this.$store.commit(COMMIT_ACTIONS.SET_AD_CONFIG, adConfig)
            })
          }
          else if (config == 'merchantConfig') {
            MerchantCommonApi.getConfig().exec().then((res) => {
              let merchantConfig = res
              this.$store.commit(COMMIT_ACTIONS.SET_MERCHANT_CONFIG, merchantConfig)
            })
          }
        }
      }
    },
    // 断言swConfig存在
    assertSwConfig() {
      this.assertStores(['swConfig'])
    },
    // // 断言AsyConfig存在
    assertOaConfig() {
      this.assertStores(['oaConfig'])
    },
    showQrCode(url){
      this.$message({
        type: 'success',
        message: '删除成功!'
      });
    }
  },

}
