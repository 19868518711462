import ProductSpecsTypeForm from "@/pages/merchant/views/products/specsType/ProductSpecsType/components/drawers/ProductSpecsTypeForm/ProductSpecsTypeForm";
import ProductSpecsTypeDetail from "@/pages/merchant/views/products/specsType/ProductSpecsType/components/drawers/ProductSpecsTypeDetail/ProductSpecsTypeDetail";

export const PRODUCT_SPECS_TYPE_ACTIONS = {
  PRODUCT_SPECS_TYPE_ADD: 'PRODUCT_SPECS_TYPE_ADD',
  PRODUCT_SPECS_TYPE_EDIT: 'PRODUCT_SPECS_TYPE_EDIT',
  PRODUCT_SPECS_TYPE_DETAIL: 'PRODUCT_SPECS_TYPE_DETAIL',
}
export const PRODUCT_SPECS_TYPE_DRAWERS = {
  PRODUCT_SPECS_TYPE_ADD: {
    action: PRODUCT_SPECS_TYPE_ACTIONS.PRODUCT_SPECS_TYPE_ADD,
    component: ProductSpecsTypeForm,
    preload: {title: '添加'},
    drawerStyle: {
      drawerClass: 'ag-drawer-mini-class',
      appendToBody: true,
    }
  },
  PRODUCT_SPECS_TYPE_EDIT: {
    action: PRODUCT_SPECS_TYPE_ACTIONS.PRODUCT_SPECS_TYPE_EDIT,
    component: ProductSpecsTypeForm,
    preload: {title: '编辑'},
    drawerStyle: {
      drawerClass: 'ag-drawer-mini-class',
      appendToBody: true,
    }
  },
  PRODUCT_SPECS_TYPE_DETAIL: {
    action: PRODUCT_SPECS_TYPE_ACTIONS.PRODUCT_SPECS_TYPE_DETAIL,
    component: ProductSpecsTypeDetail,
    preload: {title: '详情'},
    drawerStyle: {
      drawerClass: 'ag-drawer-mini-class',
      appendToBody: true,
    }
  }
}
