import OaBaseApi from "@/api/merchant/MerchantBaseApi";
import PagingKit from "@/ag/kits/PagingKit";
import FactoryItemRes from "@/entities/oa/res/factory/FactoryItemRes";
import FactoryDetailRes from "@/entities/oa/res/factory/FactoryDetailRes";
import PartnerTaskDetailRes from "@/entities/merchant/res/partner/PartnerTaskDetailRes";

export default class FactoryApi {
  /**
   * 属性分页
   * @param data
   * @returns { OaBaseApi }
   */
  static page(data = {}) {
    return new OaBaseApi('/factory/page', 'post')
      .setData(data)
      .setPageItemClassName(FactoryItemRes)
  }

  /**
   * 获取分页
   * @param data
   * @returns {PagingKit}
   */
  static paging(data) {
    return new PagingKit(FactoryApi.page(data), 10)
  }

  /**
   * 获取详情
   * @param id
   * @returns { OaBaseApi }
   */
  static detail(id) {
    return new OaBaseApi('/factory/detail', 'get')
      .setParams({id})
      .setClassName(FactoryDetailRes)
  }

  /**
  * 保存
  * @param data
   * @returns { OaBaseApi }
   */
  static update(data) {
    return new OaBaseApi('/factory/update', 'post')
      .setData(data)
      .setClassName(FactoryDetailRes)
  }

  /**
   * 创建
   * @param data
   * @returns { OaBaseApi }
   */
  static create(data) {
    return new OaBaseApi('/factory/create', 'post')
      .setData(data)
      .setClassName(FactoryDetailRes)
  }

  /**
   * 删除
   * @param ids
   * @returns { OaBaseApi }
   */
  static remove(ids) {
    return new OaBaseApi('/factory/remove', 'post')
      .setData({ids})
  }

  /**
   * 设置测试状态
   * @param id
   * @param isTest
   * @returns {*}
   */
  static setTest(id,isTest=1) {
    return new OaBaseApi('/factory/setTest', 'get')
      .setParams({id,is_test:isTest})
      .setClassName(PartnerTaskDetailRes)
  }
}
