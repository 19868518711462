import BaseRes from "@/ag/entities/res/BaseRes";

export default class UploadSignatureRes extends BaseRes {

  access_key = '' // 阿里云access_key账号id
  request_url = '' // 文件上传请求地址
  policy = '' // 策略
  signature = '' // 签名
  expire = '' // 超时时间
  callback = '' // 文件上传成功回调地址
  pre_name = '' // 文件名前缀
  OSSAccessKeyId = ''

  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
    this.OSSAccessKeyId = this.access_key
  }
}
