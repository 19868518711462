<template>
  <router-view/>
</template>
<script>
import LogoBar from '@/components/layout/LogoBar'

export default {
  name: 'Body',
  components: { LogoBar }
}
</script>

<style scoped lang="scss">
.bar{
  height: 80px;
}
</style>
