<template>
  <div class="ag-drawer-normal">
    <div class="ag-drawer-header">{{ drawer.preload.title }}</div>
    <div class="ag-drawer-body">
      <el-row>
        <el-col :xs="24" :sm="12">
          <AgDetailItem label="名称">{{ detail.name }}</AgDetailItem>
        </el-col>
        <el-col :xs="24" :sm="12">
          <AgDetailItem label="类型">{{ detail.type }}</AgDetailItem>
        </el-col>
        <el-col :xs="24" :sm="12">
          <AgDetailItem label="原始id">{{ detail.gh_id }}</AgDetailItem>
        </el-col>
        <el-col :xs="24" :sm="12">
          <AgDetailItem label="app_id">{{ detail.app_id }}</AgDetailItem>
        </el-col>

        <el-col :xs="24" :sm="12">
          <AgDetailItem label="app_key">{{ detail.app_key }}</AgDetailItem>
        </el-col>
        <el-col :xs="24" :sm="12">
          <AgDetailItem label="app_secret">{{ detail.app_secret }}</AgDetailItem>
        </el-col>
        <el-col :xs="24" :sm="12">
          <AgDetailItem label="创建时间">{{ detail.create_time }}</AgDetailItem>
        </el-col>
      </el-row>
    </div>
    <div class="ag-drawer-footer">
      <el-button size="normal" @click="agDrawerClose">关闭</el-button>
    </div>
  </div>
</template>

<script>
import Index from './index'
import FormEmployeeInput from "@/components/oa/form/form-employee-input";
import AgDetailItem from "@/ag/components/AgDetail/AgDetailItem";

export default {
  name: 'NoticeDetail',
  components: {AgDetailItem, FormEmployeeInput},
  mixins:[Index]
}
</script>

<style scoped lang="sass">
@import "index"
</style>
