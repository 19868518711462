import BaseRes from "@/ag/entities/res/BaseRes";

export default class DecorationPropItemRes extends BaseRes {

    id = '' // id
    cover = '' // 属性封面
    name = '' // 属性名称
    max_count = '' // 最大个数
    min_count = '' // 最小个数
    create_time = '' // 创建时间
  
  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }
}