import {BaseMixin} from "@/ag/mixins/BaseMixin";
import Auth from "@/core/Auth";

export default {
  mixins:[BaseMixin],
  props:{},
  data(){
    return {}
  },
  created() {
    Auth.logout()
  },
  methods:{}
}
