export default class ResultKit {
  constructor(code = -1, msg = '', data = null) {
    this.code = code
    this.ok = code === 1
    this.msg = msg
    this.data = data
  }

  /**
   * 封装结果
   * @param {*} res 返回结果res
   */
  static setResult(res) {
    const {
      code,
      msg,
      data
    } = res
    return new ResultKit(code, msg, data)
  }

  /**
   * 强制转换为成功的请求
   * @param {*} data 成功需要返回的数据源
   * @param {*} msg 成功的消息提醒
   */
  static OK(data, msg = '操作成功') {
    return new ResultKit(1, msg, data)
  }

  /**
   * 强制转换为失败的请求
   * @param msg
   * @param data
   * @param code
   * @returns {ResultKit}
   * @constructor
   */
  static Failed(msg = '操作失败', code = -1, data = null) {
    return new ResultKit(code, msg, data)
  }

  /**
   * 请求是否成功
   */
  isOK() {
    return this.ok
  }
}
