import {BaseMixin} from "@/ag/mixins/BaseMixin";

export const DrawerMixin = {
  mixins:[BaseMixin],
  props: {
    drawer: { // 携带的信息
      type: Object,
      default: () => {
        return {
          action:'',
          preload:{}
        }
      },
    }
  },
  data(){
    return {
      agAction:''
    }
  },
  created() {
    this.agAction = this.agGetAction()
  },
  methods: {
    // action
    agGetAction(){
      const {action} = this.drawer
      return action
    },
    // 附加信息
    agGetPreload(){
      const {preload} = this.drawer
      return preload
    },
    // 发送抽屉事件
    agDrawerDispatchAction(data={}) {
      this.$parent.$emit('onDrawerAction', {action:this.agGetAction(),drawer: this.drawer, data})
    },
    // 关闭
    agDrawerClose() {
      this.$parent.$emit('onDrawerClose')
    },
  }
}
