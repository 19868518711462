import {Message, MessageBox} from 'element-ui'
import {Tabs} from "@/consts/Tabs";

export default class UiKit {
  static prompt(message, title = '提示', options) {
    return MessageBox.prompt(message, title, options)
  }
  static alert(message, title = '提示', options) {
    return MessageBox.alert(message, title, options)
  }

  static confirm(message, title = '提示', options) {
    return MessageBox.confirm(message, title, options)
  }
  static success(msg) {
    return Message.success(msg)
  }

  static error(err) {
    return Message.error(err)
  }

  static nav(url) {
    window.location.href = url
  }

  static navHome() {
    UiKit.nav(Tabs.WORKBENCH.url)
  }

  static nav2Login(redirect = '') {
    window.location.href = `/index/#/login?redirect=${encodeURIComponent(redirect)}`
  }

  static toastResult(result) {
    if (result.isOK()) {
      UiKit.success(result.msg)
    } else {
      UiKit.error(result.msg)
    }
  }

}
