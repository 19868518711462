import SparkMD5 from 'spark-md5'

export default class OssKit {
  /**
   * 获取文件MD5
   * @param file 文件对象
   * @returns {Promise<unknown>}
   */
  static getFileMd5(file){
    return new Promise(resolve => {
      //计算文件md5值
      let fileReader = new FileReader()
      let spark = new SparkMD5.ArrayBuffer()
      fileReader.readAsArrayBuffer(file)//获取文件二进制数据
      fileReader.onload = function (e) {
        spark.append(e.target.result)
        let fileMd5 = spark.end()
        resolve(fileMd5)
      };
    })
  }
}
