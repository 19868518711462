/**
 * 0点
 * @returns {Date}
 */
export function zeroHoursForToday() {
  return new Date(new Date().setHours(0, 0, 0))
}

/**
 * 23:59:59
 * @returns {Date}
 */
export function twentyThreeHoursForToday() {
  return new Date(new Date().setHours(23, 59, 59))
}

// 获得某月的天数
function getMonthDays(myMonth) {
  var now = new Date() // 当前日期
  var nowYear = now.getFullYear() // 当前年
  var lastMonthDate = new Date() // 上月日期
  lastMonthDate.setDate(1)
  lastMonthDate.setMonth(lastMonthDate.getMonth() - 1)
  var monthStartDate = new Date(nowYear, myMonth, 1)
  var monthEndDate = new Date(nowYear, myMonth + 1, 1)
  var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24)
  return days
}

// 获得本月的开始日期
export function getMonthStartDate(month = 0) {
  let now = new Date() // 当前日期
  return new Date(now.getFullYear(), month != 0 ? month - 1 : now.getMonth(), 1, 0, 0, 0)
}

// 获得本月的结束日期
export function getMonthEndDate(month = 0) {
  let now = new Date() // 当前日期
  month = month != 0 ? month - 1 : now.getMonth()
  let lastData = new Date()
  lastData.setMonth(month + 1)
  lastData.setDate(0)
  return new Date(lastData.getFullYear(), lastData.getMonth(), lastData.getDate(), 23, 59, 59)
}

// 获得上个月的开始日期
export function getPreMonthStartDate() {
  let now = new Date() // 上月日期
  let month = now.getMonth();
  now.setMonth(month - 1)
  return new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0)
}

// 获得上个月的结束日期
export function getPreMonthEndDate() {
  let now = new Date() // 上月日期
  return new Date(now.getFullYear(), now.getMonth(), 0, 23, 59, 59)
}
