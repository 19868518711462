import HttpKit from '@/ag/kits/HttpKit'

export default class BaseApi {
  url = '' // 请求连接
  isShowErr = true // 是否显示错误信息
  method = 'get' // 请求方法
  headers = {} // 请求头
  params = {} // get请求参数
  data = {} // post 数据
  className = null // 转换成结果
  listItemClassName = null // 用于列表
  pageItemClassName = null // 用于分页

  onLoadingChange = () => {
  }

  constructor(url, method = 'get', isShowErr = true) {
    this.url = url
    this.method = method
    this.isShowErr = isShowErr
  }

  setUrl(url) {
    this.url = url;
    return this;
  }

  setIsShowErr(isShowErr) {
    this.isShowErr = isShowErr;
    return this;
  }

  setMethod(method) {
    this.method = method;
    return this;
  }

  setParams(params) {
    this.params = params;
    return this;
  }
  addParams(params) {
    this.params = {...this.params,...params};
    return this;
  }

  setHeaders(headers) {
    this.headers = headers;
    return this;
  }
  addHeaders(headers) {
    this.headers = {...this.headers,...headers};
    return this;
  }
  setData(data) {
    this.data = data;
    return this;
  }
  addData(data) {
    this.data = {...this.data,...data};
    return this;
  }

  setClassName(className) {
    this.className = className;
    return this;
  }

  setListItemClassName(className) {
    this.listItemClassName = className;
    return this;
  }
  setPageItemClassName(className,key='list') {
    this.pageItemClassName = className;
    return this;
  }

  setOnLoadingChange(onLoadingChange) {
    this.onLoadingChange = onLoadingChange;
    return this;
  }

  /**
   * 执行
   * @returns {Promise<unknown>}
   */
  exec() {
    if (this.method.toUpperCase() === 'GET') {
      return this.get(this.params)
    } else {
      return this.post(this.data)
    }
  }

  get(params = {}) {
    return this.request(params, null, 'get')
  }

  post(data = {}) {
    return this.request(null, data, 'post')
  }

  request(params = {}, data = {}, method = 'get') {
    if (this.onLoadingChange) {
      this.onLoadingChange(true)
    }
    let promise = new Promise((resolve, reject) => {
      const url = this._appendUrl(this.url)
      HttpKit.REQUEST(url, params, data, method, {...this.headers,...this._getHeaders()})
        .then((result) => {
          resolve(result)
        })
        .catch((result) => {
          console.error(`request url:${url}`,result)
          if (this.isShowErr) {
            this._showErr(result.msg)
          }
          reject(result)
        })
        .finally(() => {
          if (this.onLoadingChange) {
            this.onLoadingChange(false)
          }
        })
    })
    if (this.className) {
      return promise.then(HttpKit.toResClass(this.className))
    } else if (this.listItemClassName) {
      return promise.then(HttpKit.toListResClass(this.listItemClassName))
    }else if (this.pageItemClassName) {
      return promise.then(HttpKit.toPageResClass(this.pageItemClassName))
    }
    return promise
  }


  // 加上域名
  _appendUrl(url) {
    return `${this._getBaseUrl()}${url}`
  }

  // 子类重载
  _showErr(err) {
    console.error('子类未设置 _showErr')
  }

  // 子类重载 获取域名
  _getBaseUrl() {
    console.error('子类未设置 _getBaseUrl')
    return ''
  }

  // 子类重载
  _getHeaders() {
    return {}
  }
}
