import {FormMixin} from "@/ag/mixins/FormMixin";
import IndexLoginApi from '@/api/index/IndexLoginApi'
import UiKit from "@/ag/kits/UiKit";
import LoginDto from "@/entities/index/dto/login/LoginDto";
import CacheKit from "@/ag/kits/CacheKit";
import {CacheKey} from "@/consts/CacheKey";

export default {
  mixins: [FormMixin],
  data() {
    return {
      loginBkg:require('@/assets/login-bkg.png/'),
      form: {},
      autoLogin: true,
      token: null,
    }
  },
  created() {
    this.form = new LoginDto()
  },
  methods: {
    onNextClick() {
      IndexLoginApi.checkAccountByPhone(this.form.phone)
        .setOnLoadingChange(this.onAgSubmitChange)
        .exec()
        .then((dto) => {
          if (!dto.isEmployee()) {
            UiKit.alert('账号未注册，请联系管理员')
          } else {
            this.setAction(this.ACTIONS.LOGIN)
          }
        })
    },
    onLoginClick() {
      let dto = this.form.toPhoneDto()
      IndexLoginApi.loginPhoneByPassword(dto).setOnLoadingChange(this.onAgSubmitChange).exec()
        .then((loginRes) => {
          this.token = loginRes.token
          this.getLoginEntList()
        })
    },
    async getLoginEntList() {
      try {
        const entList = await IndexLoginApi.getLoginEntList(this.token).setOnLoadingChange(this.onAgSubmitChange).exec()
        if (entList.length === 1) {
          await this.loginByEnt(this.token,entList[0].id)
        } else {
          // 选择企业
          await this.loginByEnt(this.token,entList[0].id)
          // this.setCardType(this.CARD_VIEWS.SELECT_ENT)
        }
      } catch (e) {

      }
    },
    async loginByEnt(token,entId){
      try {
        CacheKit.set(CacheKey.TOKEN,token)
        await IndexLoginApi.loginByEnt(token, entId).exec()
        this.navHome()
      }catch (e){
        return UiKit.nav2Login()
      }
    }
  }
}
