<template>
  <el-select :style="{width:width}" :size="size" v-model="currentValue" :placeholder="placeholder" clearable class="filter-item" @change="handleModelInput">
    <el-option v-for="(item,i) in statusList" :key="i" :label="item[props.label]" :value="item[props[itemKey]]"/>
  </el-select>
</template>

<script>

export default {
  name: 'AgTableFilterStatus',
  props: {
    props:{
      type: [Object],
      default(){
        return {
          label:'label',
          value:'value',
        }
      }
    },
    placeholder: {
      type: [String],
      default: null
    },
    width: {
      type: [String],
      default: "100px"
    },
    size: {
      type: [String],
      default: 'medium'
    },
    statusList: {
      type: [Object,Array],
      default: null
    },
    value: {
      type: [Number, String],
      default: null
    },
    itemKey:{
      type: [String],
      default: 'value'
    }
  },
  data() {
    return {
      currentValue: this.value
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue
    }
  },
  methods: {
    handleModelInput(event) {
      this.$emit('input', this.currentValue)
    }
  }
}
</script>

<style scoped>

</style>
