import {BaseDto} from "@/ag/entities/dto/BaseDto";

export default class PagesDto extends BaseDto{

      id = '' // 自增id
      mini_id = '' // 关联小程序id
      path = '' // 页面路径
      name = '' // 名称
      create_time = '' // 创建时间
  
  $rules = {
    mini_id : this.required('必填'),
    path : this.required('必填'),
    name : this.required('必填'),
  }
  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }

  toDto() {
    let dto = super.toDto();
    return dto
  }
}
