import AppletsBaseApi from "@/api/applets/AppletsBaseApi";
import PagingKit from "@/ag/kits/PagingKit";
import ParamsItemRes from "@/entities/applets/res/params/ParamsItemRes";
import ParamsDetailRes from "@/entities/applets/res/params/ParamsDetailRes";

export default class ParamsApi {
  /**
   * 属性分页
   * @param data
   * @returns { AppletsBaseApi }
   */
  static page(data = {}) {
    return new AppletsBaseApi('/miniProgram/miniParams/page', 'post')
      .setData(data)
      .setPageItemClassName(ParamsItemRes)
  }

  /**
   * 获取分页
   * @param data
   * @returns {PagingKit}
   */
  static paging(data) {
    return new PagingKit(ParamsApi.page(data), 10)
  }

  /**
   * 获取详情
   * @param id
   * @returns { AppletsBaseApi }
   */
  static detail(id) {
    return new AppletsBaseApi('/miniProgram/miniParams/detail', 'get')
      .setParams({id})
      .setClassName(ParamsDetailRes)
  }

  /**
  * 保存
  * @param data
   * @returns { AppletsBaseApi }
   */
  static update(data) {
    return new AppletsBaseApi('/miniProgram/miniParams/update', 'post')
      .setData(data)
      .setClassName(ParamsDetailRes)
  }

  /**
   * 创建
   * @param data
   * @returns { AppletsBaseApi }
   */
  static create(data) {
    return new AppletsBaseApi('/miniProgram/miniParams/create', 'post')
      .setData(data)
      .setClassName(ParamsDetailRes)
  }

  /**
   * 删除
   * @param ids
   * @returns { AppletsBaseApi }
   */
  static remove(ids) {
    return new AppletsBaseApi('/miniProgram/miniParams/remove', 'post')
      .setData({ids})
  }
}
