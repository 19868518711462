<template>
  <div class="workbench-bar ag-flex-column-center">
    <div class="ag-width-1200 ag-flex-row-center ag-flex-between">
      <a :href="workbench.url" class="">
        <LogoBar/>
      </a>
      <div class="ag-flex-row-center ag-flex-shrink">
        <div v-for="tab in tabs" class="tab-item ag-flex-center ag-flex-around" :class="{'active':active===tab.type}">
          <el-link :underline="false" :href="tab.url">{{ tab.name }}</el-link>
        </div>
        <el-link class="logout" :underline="false" @click="onLogoutClick">{{logout.name}}</el-link>
      </div>
    </div>
  </div>
</template>

<script>
import LogoBar from '@/components/layout/LogoBar'
import WorkbenchBar from './index'

export default {
  mixins: [WorkbenchBar],
  name: 'WorkbenchBar',
  components: { LogoBar },
}
</script>

<style scoped lang="scss">
.workbench-bar{
  min-width: 1200px;
}
.tab-item .el-link{
  font-size: 16px;
}
.tab-item {
  height: 60px;
  min-width: 130px;
  border-bottom: 3px solid transparent;
}
.tab-item.active {
  color: #F2641D;
  border-bottom: 3px solid #F2641D;
}
.tab-item.active .el-link {
  color: #F2641D !important;
}
.logout{
  font-size: 12px;
  border-bottom: 3px solid transparent;
  margin-left: 30px;
  margin-right: 8px;
}
</style>
