import OaBaseApi from '@/api/oa/OaBaseApi'
import employeeItemRes from '@/entities/oa/res/employee/EmployeeItemRes'
import EmployeeBaseRes from '@/entities/oa/res/employee/EmployeeBaseRes'
import EmployeeDetailRes from '@/entities/oa/res/employee/EmployeeDetailRes'

export default class OaEmployeeApi {
  /**
   * 通过部门id获取员工列表
   * @returns {OaBaseApi}
   */
  static getEmployeeList(architecture_id) {
    return new OaBaseApi('/company/employee/list')
      .setParams({architecture_id})
      .setListItemClassName(employeeItemRes)
  }
  /**
   * 通过员工ids获取员工列表
   * @param ids 员工id
   * @returns {OaBaseApi}
   */
  static getEmployeeListByIds(ids) {
    return new OaBaseApi('/company/employee/getListByIds', 'post')
      .setData({ids})
      .setListItemClassName(EmployeeBaseRes)
  }
  /**
   * 创建员工
   * @param data
   * @returns {OaBaseApi}
   */
  static create(data) {
    return new OaBaseApi('/company/employee/create', 'post')
      .setData(data)
      .setClassName(EmployeeDetailRes)
  }
  /**
   * 获取详情
   * @returns {OaBaseApi}
   */
  static detail(id) {
    return new OaBaseApi('/company/employee/detail')
      .setParams({id})
      .setClassName(EmployeeDetailRes)
  }
  /**
   * 修改员工
   * @returns {OaBaseApi}
   */
  static update(data){
    return new OaBaseApi('/company/employee/update', 'post')
      .setData(data)
      .setClassName(EmployeeDetailRes)
  }
  /**
   * 从部门移除员工
   * @returns {OaBaseApi}
   */
  static delEmployeeByArchitecture(data){
    return new OaBaseApi('/company/employee/removeArchitecture', 'post')
      .setData(data)
  }
}

