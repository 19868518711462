import BaseRes from "@/ag/entities/res/BaseRes";

export default class ProductSpecsTypeItemRes extends BaseRes {

  id = '' // ID
  class_id = '' // 产品分类ID
  name = '' // 类型名称
  status = '' // 状态
  is_img = '' // 图片
  weight = '' // 排序
  delete_time = '' // 软删除
  create_time = '' // 创建时间

  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }
}