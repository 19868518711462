export const Tabs = {
  CONTACTS: {
    type: 'contacts',
    name: '通讯录',
    url: '/oa/#/contacts'
  },
  WORKBENCH: {
    type:'workbench',
    name:'工作台',
    url:'/oa/#/workbench'
  },
  SETTING: {
    type:'setting',
    name:'设置',
    url:'/oa/#/setting/auth'
  },
  LOGOUT: {
    type:'logout',
    name:'退出',
    url:'/index/#/logout'
  },
  getTabs(){
    return [
      this.CONTACTS,
      this.WORKBENCH,
      this.SETTING
    ]
  }
}
