import BaseRes from "@/ag/entities/res/BaseRes";


export default class AdConfigRes extends BaseRes {

  adStatus = [] //广告状态
  adChannel = []//广告渠道
  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }
}
