<template>
  <div class="upload-container">
    <el-button :style="{background:color,borderColor:color}" icon="el-icon-upload" size="mini" type="primary" @click=" dialogVisible=true">
      图片上传
    </el-button>
    <el-dialog :append-to-body="true" v-if="dialogVisible" :visible.sync="dialogVisible" :close-on-click-modal="false" @closed="onDialogClose">
      <AgFormUpload
        :app="app"
        v-model="fileList"
        :multiple="true"
        :crop="true"
        :crop-width="600"
        :crop-height="600"
        tip="提示"
      />
      <div>
        <el-button @click="dialogVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="handleSubmit">
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { uploadFile } from '@/api/common/config'
import { Message } from 'element-ui'
import AgFormUpload from "@/ag/components/AgForm/AgFormUpload";
const ImageCompressor = require('js-image-compressor')
export default {
  name: 'EditorSlideUpload',
  components: {AgFormUpload},
  props: {
    app: {type: String, default: 'oa'},
    color: {
      type: String,
      default: '#1890ff'
    }
  },
  data() {
    return {
      dialogVisible: false,
      listObj: {},
      fileList: []
    }
  },
  watch: {
    dialogVisible(newValue) {
      this.dialogVisible = newValue
      if (!this.dialogVisible) {
        this.listObj = {}
        this.fileList = []
      }
    }
  },
  destroyed() {
    this.listObj = {}
  },
  methods: {
    onBeforeUnload(file) {
      const sizeOfM = file.size / 1024 / 1024
      if (sizeOfM > this.maxSize) {
        Message.error('文件大小不能超过' + this.maxSize + 'M')
        return false
      }
      const _self = this
      const _URL = window.URL || window.webkitURL
      const fileName = file.uid
      this.listObj[fileName] = {}

      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = _URL.createObjectURL(file)
        img.onload = function() {
          _self.listObj[fileName] = { hasSuccess: false, uid: file.uid, width: this.width, height: this.height }
        }

        // 小于100K，不用处理了
        if (file.size / 1024 < 100) { resolve(true) }

        const options = {
          file,
          quality: this.quality,
          mimeType: file.type ? file.type : 'image/jpeg',
          maxWidth: this.maxWidth,
          maxHeight: this.maxHeight,
          // 压缩前回调
          beforeCompress: function(result) {},
          success: function(result) {
            resolve(result)
          }, // 发生错误
          error: function(msg) {
            Message.error(msg)
            delete _self.listObj[fileName]
            reject(msg)
          }
        }
        new ImageCompressor(options)
      })
    },
    onRequest(fileIfo) {
      // 独立上传文件
      const { data, filename, file } = fileIfo
      // 新建formDate对象
      const formData = new FormData()
      for (const key in data) {
        formData.append(key, data[key])
      }
      // 文件单独push,第三个参数指定上传的文件名
      formData.append(filename, file, file.name)
      // return uploadFile(formData)
      // return Promise.resolve(ResultKit.OK({ url: 'https://cdn.slab.asymall.com/huazh/api/uploads/20210130/d7d1105b0a6d1558a7f4c70148523aac.png' }))
    },
    checkAllSuccess() {
      return Object.keys(this.listObj).every(item => this.listObj[item].hasSuccess)
    },
    handleSubmit() {
      // const arr = Object.keys(this.listObj).map(v => this.listObj[v])
      // if (!this.checkAllSuccess()) {
      //   this.$message('图片还在上传中...')
      //   return
      // }
      this.$emit('successCBK', this.fileList)
      this.listObj = {}
      this.fileList = []
      this.dialogVisible = false
    },
    handleSuccess(response, file) {
      const uid = file.uid
      const objKeyArr = Object.keys(this.listObj)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          this.listObj[objKeyArr[i]].url = response.data.url
          this.listObj[objKeyArr[i]].hasSuccess = true
          return
        }
      }
    },
    handleError(response, file) {
      const uid = file.uid
      const objKeyArr = Object.keys(this.listObj)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          delete this.listObj[objKeyArr[i]]
          return
        }
      }
    },
    handleRemove(file) {
      const uid = file.uid
      const objKeyArr = Object.keys(this.listObj)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          delete this.listObj[objKeyArr[i]]
          return
        }
      }
    },
    // beforeUpload(file) {
    //   const _self = this
    //   const _URL = window.URL || window.webkitURL
    //   const fileName = file.uid
    //   this.listObj[fileName] = {}
    //   return new Promise((resolve, reject) => {
    //     const img = new Image()
    //     img.src = _URL.createObjectURL(file)
    //     img.onload = function() {
    //       _self.listObj[fileName] = { hasSuccess: false, uid: file.uid, width: this.width, height: this.height }
    //     }
    //     resolve(true)
    //   })
    // },
    onDialogClose() {
      console.log()
      this.listObj = {}
    }
  }
}
</script>

<style lang="scss" scoped>
.editor-slide-upload {
  margin-bottom: 20px;
  ::v-deep .el-upload--picture-card {
    width: 100%;
  }
}
</style>
