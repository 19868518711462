import {BasePageMixin} from "@/mixins/BasePageMixin";
import ParamsDetailRes from "@/entities/applets/res/params/ParamsDetailRes";
import ParamsApi from "@/api/applets/params/ParamsApi";
import {DrawerMixin} from "@/ag/mixins/components/DrawerMixin";


export default {
  mixins: [BasePageMixin,DrawerMixin],
  props: {},
  data() {
    return {
      detail:new ParamsDetailRes()
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      const {id} = this.agGetPreload()
      ParamsApi.detail(id).exec().then((res) => {
        this.detail = res
      })
    },
  }
}
