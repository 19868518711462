import BaseRes from '@/ag/entities/res/BaseRes'

export default class LoginRes extends BaseRes{
  token =  "" // token

  constructor(data) {
    super();
    this.initProp(data)
  }
  onAfterSetProp() {
  }
}
