import UploadSignatureRes from "@/entities/asy/res/common/UploadSignatureRes";
import AsyBaseApi from "@/api/asy/AsyBaseApi";
import AsyConfigRes from "@/entities/asy/res/common/AsyConfigRes";
import MerchantBaseApi from "@/api/merchant/MerchantBaseApi";
import MerchantConfigRes from "@/entities/merchant/res/common/MerchantConfigRes";
import DecorationPropAndItemsRes from "@/entities/asy/dto/cms/decoration/prop/DecorationPropAndItemsRes";

export default class AsyCommonApi {

  /**
   * 获取ASY全局配置
   * @returns {AsyBaseApi}
   */
  static getAsyConfig() {
    return new AsyBaseApi('/common/getMacroConfig')
      .setClassName(AsyConfigRes)
  }

  /**
   * 获取oa全局配置
   * @returns {AsyBaseApi}
   */
  static getConfig() {
    return new AsyBaseApi('/common/getMacroConfig')
      .setClassName(AsyConfigRes)
  }

  /**
   * 爱石艺
   * @param appPath
   * @returns {AsyBaseApi}
   */
  static getOssSignature(appPath = 'oa') {
    return new AsyBaseApi('/common/getUploadSignature')
      .setParams({file_path: appPath})
      .setClassName(UploadSignatureRes)
  }

  static getConfig() {
    return new AsyBaseApi('/common/getMacroConfig')
      .setClassName(MerchantConfigRes)
  }

  static getProductClassTree() {
    return new AsyBaseApi('/common/productClassTree ')
  }

  /**
   * 获取所有的属性和属性值
   * @param type 0全部1案例2板材
   * @returns { AsyBaseApi }
   */
  static AllPropertyAndItems(type) {
    return new AsyBaseApi('/decoration/property/all_property_and_items', 'get')
      .setParams({type})
      .setListItemClassName(DecorationPropAndItemsRes)
  }
}

