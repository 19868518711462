import {BaseMixin} from "@/ag/mixins/BaseMixin";
import {appendUrlParams, deepClone} from "@/ag/utils";
import UiKit from "@/ag/kits/UiKit";

export default {
  mixins: [BaseMixin],
  data() {
    return {
      opens: [],
      menus: [],
      isGroup: false,
    }
  },
  created() {
    this.handleMenus(this.$store.state.global.app)
    this.isGroup = this.app.app_type == 0

  },
  computed: {
    activeMenu() {
      const route = this.$route
      let menuPath = route.fullPath
      if (this.isGroup) {
        menuPath =  '/'+this.$store.state.model+'/#'+route.fullPath
      }
      console.log('this.isGroup',this.isGroup)
      console.log('menuPath',menuPath)
      return menuPath
    },
    app() {
      let tempApp = this.$store.state.global.app
      this.isGroup = tempApp.app_type == 0
      return this.$store.state.global.app
    }
  },
  watch: {
    app() {
      console.log("this.app", this.app)
      this.handleMenus(this.app)
    }
  },
  methods: {
    getRoute(app) {
      let route = app.web_url
      let appId = this.$store.state.global.appId
      if (appId) {
        route = appendUrlParams(route, {appId})
      }
      console.log('getRoute',route)
      return route
    },
    getRouteObj(app) {
      return {path: app.web_url, query: {appId: this.$store.state.global.appId}}
    },
    handleMenus(app) {
      this.opens = []
      if (!app || Object.keys(app).length < 1) {
        return []
      }
      let clone = deepClone(app)
      let routes = clone.children
      let recursion = (routes, deep = 0, index = 0, parent = null) => {
        let res = []
        if (!routes || routes.length < 1) {
          return null
        }
        for (const i in routes) {
          let item = routes[i]
          if (!item.isShow) {
            continue
          }
          item._index = `${deep}-${index}-${i}`
          item.children = recursion(item.children, deep + 1, i, item)
          if (item.children && item.children.length === 1) {
            item.single = true
            item.singleMenu = item.children[0]
          }
          if (item.children && item.children.length == 0) {
            item.children = null;
          }
          // 默认展开的菜单
          if (item && this.activeMenu && item.web_url == this.activeMenu) {
            if (parent) {
              this.opens.push(parent.web_url)
            } else if (item.web_url) {
              this.opens.push(item.web_url)
            }
          }
          res.push(item)
        }
        return res
      }
      let menus = recursion(routes)
      console.log('menus',menus)
      this.menus = menus
    },
    onItemSelect(index, indexPath) {
      // app是分组的时候直接
      if (this.isGroup) {
        UiKit.nav(index)
      }
    }
  }
}
