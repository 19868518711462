<template>
  <div>404</div>
</template>

<script>
import Index from './index'

export default {
  name: 'Page404',
  mixins:[Index]
}
</script>

<style scoped lang="sass">
@import "index"
</style>
