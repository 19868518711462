export default {
  getUserInfo(state) {
    return state.userInfo
  },
  getOaConfig(state) {
    return state.oaConfig
  },
  getSwConfig(state) {
    return state.swConfig
  }
}
