import BaseRes from "@/ag/entities/res/BaseRes";
import {toClass} from "@/ag/utils";
import EmployeeItemRes from "@/entities/oa/res/employee/EmployeeItemRes";
import ArchitectureItemRes from "@/entities/oa/res/architecture/ArchitectureItemRes";

export default class UserInfoRes extends BaseRes {
  id = '' // id
  account = '' // 登陆账号
  employee = {} // 员工
  architecture = {} // 公司

  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
    this.employee = toClass(this.employee, EmployeeItemRes)
    this.architecture = toClass(this.architecture, ArchitectureItemRes)
  }
}
