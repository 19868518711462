import BaseRes from "@/ag/entities/res/BaseRes";

export default class ProductsItemRes extends BaseRes {

    id = '' // id
      name = '' // 名称
      class_pid = '' // 一级分类
      class_cid = '' // 二级分类
      region_address = '' // 产地
      unit = '' // 单位
      status = '' // 状态；1上架，2下架
      sort = '' // 排序
      is_pub_desc = '' // 公共服务;1否,2是
      stock = '' // 库存
      view_count = '' // 浏览数
      favorite_count = '' // 喜欢数
      cover = '' // 封面
      banner_images = '' // 轮播图
      detail_images = '' // 详情图
      remark = '' // 备注
      create_time = '' // 创建时间
      update_time = '' // 更新时间
      delete_time = '' // 软删除标识(删除时间)
  
  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }
}