import AppletsBaseApi from "@/api/applets/AppletsBaseApi";
import PagingKit from "@/ag/kits/PagingKit";
import NoticeItemRes from "@/entities/applets/res/notice/NoticeItemRes";
import NoticeDetailRes from "@/entities/applets/res/notice/NoticeDetailRes";

export default class NoticeApi {
  /**
   * 属性分页
   * @param data
   * @returns { AppletsBaseApi }
   */
  static page(data = {}) {
    return new AppletsBaseApi('/miniProgram/miniProgram/page', 'post')
      .setData(data)
      .setPageItemClassName(NoticeItemRes)
  }

  /**
   * 获取分页
   * @param data
   * @returns {PagingKit}
   */
  static paging(data) {
    return new PagingKit(NoticeApi.page(data), 10)
  }

  /**
   * 获取详情
   * @param id
   * @returns { AppletsBaseApi }
   */
  static detail(id) {
    return new AppletsBaseApi('/miniProgram/miniProgram/detail', 'get')
      .setParams({id})
      .setClassName(NoticeDetailRes)
  }

  /**
  * 保存
  * @param data
   * @returns { AppletsBaseApi }
   */
  static update(data) {
    return new AppletsBaseApi('/miniProgram/miniProgram/update', 'post')
      .setData(data)
      .setClassName(NoticeDetailRes)
  }

  /**
   * 创建
   * @param data
   * @returns { AppletsBaseApi }
   */
  static create(data) {
    return new AppletsBaseApi('/miniProgram/miniProgram/create', 'post')
      .setData(data)
      .setClassName(NoticeDetailRes)
  }

  /**
   * 删除
   * @param ids
   * @returns { AppletsBaseApi }
   */
  static remove(ids) {
    return new AppletsBaseApi('/miniProgram/miniProgram/remove', 'post')
      .setData({ids})
  }
}
