import {BaseDto} from "@/ag/entities/dto/BaseDto";

export default class LoginDto extends BaseDto{
  phone = ''
  password = ''
  code = ''
  area = '+86'

  $vue = {
  }

  toPhoneDto(){
    return {
      phone:this.phone,
      password:this.password
    }
  }

  validate() {
    if (!this.phone){return false}
    if (!this.password){return false}
    return true
  }
}
