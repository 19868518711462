import BaseRes from '@/ag/entities/res/BaseRes'
import {Macros} from "@/consts/Macros";

export default class CheckAccountRes extends BaseRes{
  is_employee = 1 // 是否企业员工 1否 2是
  constructor(data) {
    super();
    this.initProp(data)
  }
  onAfterSetProp() {
  }
  isEmployee(){
    return this.is_employee === Macros.YES
   }
}
