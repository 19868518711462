import {DrawerPageMixin} from "@/mixins/DrawerPageMixin";
import {ListPageMixin} from "@/mixins/ListPageMixin";
import PagesApi from "@/api/applets/pages/PagesApi";
import { PAGES_ACTIONS, PAGES_DRAWERS } from "@/pages/applets/views/pages/Pages/macros";
import UiKit from "@/ag/kits/UiKit";
import {getKeyValuesByArray} from "@/ag/utils";
import {DrawerMixin} from "@/ag/mixins/components/DrawerMixin";

export default {
  mixins: [ListPageMixin,DrawerPageMixin,DrawerMixin],
  props: {},
  data() {
    return {
      agAuthMapper: {
        add: '/miniProgram/miniPage/create',
        edit: '/miniProgram/miniPage/update',
        remove: '/miniProgram/miniPage/remove',
        detail: '/miniProgram/miniPage/detail',
        page: '/miniProgram/miniPage/page',
      },
      agPaging :PagesApi.paging(),
      agUpdateApi :PagesApi.update(),
      agSort:[{key:'create_time',sort:'desc'}],
      // 取消自动刷新
      isAgAutoLoad:false
    }
  },
  created() {
    this.agFilterQuery.mini_id = this.drawer.preload.id
    this.agDrawerBindAction(PAGES_ACTIONS.PAGES_ADD, this.onActionAdd)
    this.agDrawerBindAction(PAGES_ACTIONS.PAGES_EDIT, this.onActionEdit)
    this.agRefresh(true)
  },
  methods: {
    onAddClick() {
      this.agCreateDrawer(PAGES_DRAWERS.PAGES_ADD, {id:'',mini_id:this.drawer.preload.id})
    },
    onEditClick({id}) {
      this.agCreateDrawer(PAGES_DRAWERS.PAGES_EDIT, {id})
    },
    onDetailClick({id}){
      this.agCreateDrawer(PAGES_DRAWERS.PAGES_DETAIL, {id,detail:true})
    },
    onDelClick({id,name }) {
      UiKit.confirm(`确定要删除 ${ name } ?`,'提示').then(()=>{
        PagesApi.remove([id]).exec().then(()=>{
          UiKit.success('删除成功')
          this.agRefresh()
        })
      })
    },
    onMultiDelClick(){
      let ids = getKeyValuesByArray(this.agSelections,'id')
      UiKit.confirm(`确定要删除这 ${ids.length} 项吗?`,'提示').then(()=>{
        PagesApi.remove(ids).exec().then(()=>{
          UiKit.success('删除成功')
          this.agRefresh()
        })
      })
    },
    onActionAdd({drawer,data}) {
      this.agRefresh();
    },
    onActionEdit({drawer,data}) {
      this.agRefresh();
    },
  }
}
