import {BasePageMixin} from "@/mixins/BasePageMixin";
// page 管理
export const DrawerPageMixin = {
  mixins: [BasePageMixin],
  data() {
    return {
      agDrawerData: {
        visible: false,  // 抽屉可视
        action: '',      // 哪个抽屉
        preload: {},     // 携带信息
        component: null,  // 抽屉组件
      }
    }
  },

  created() {
    this._drawerListeners = {}
  },
  methods: {
    /**
     * 创建抽屉
     * @param drawerData 与agDialogData 数据结构一致
     * @param preload 携带信息 会传入drawer组件里面
     * @param drawerStyle drawer的样式，详见 AgDrawer组件
     */
    agCreateDrawer(drawerData, preload = {}, drawerStyle = {}) {
      let data = {...drawerData, visible: true}
      data.preload = {...data.preload, ...preload}
      data.drawerStyle = {...data.drawerStyle, ...drawerStyle}
      this.agDrawerData = data
    },
    // 关闭抽屉
    agCloseDrawer() {
      this.agDrawerData = {visible: false};
    },
    // 默认处理抽屉事件
    onAgDrawerAction({drawer, data}) {
      const {action} = drawer
      const listener = this._drawerListeners[action]
      if (listener) {
        listener({drawer, data})
      }
    },
    // 默认处理抽屉事件
    agDrawerBindAction(action, listener) {

      this._drawerListeners[action] = listener
      // console.log('_drawerListeners==>', this._drawerListeners)
    }


  }
}
