import UiKit from "@/ag/kits/UiKit";

export default class PagingKit {

  constructor(request, pageSize = 10, pageNumber = 1) {
    /**
     * @var BaseApi
     * @private
     */
    this._request = request // httpkit
    this._current_page = pageNumber; // 第几页
    this._per_page = pageSize; // 没有多少个数据
    this._currentPageDatas = [] // 当前数据
    this._total = 0 // 总条数
  }

  /**
   *
   * @param refresh
   * @returns {Promise<(*&{hasMore: boolean, items: *[], longList: *[]})|(*&{hasMore: *, items: *, longList: *})|*>}
   */
  async exec(refresh = false) {
    // 重置
    refresh && this._reset();
    try {
      let result = await this._request.exec()
      const {total, list} = result;
      this._currentPageDatas = list
      return {
        list: this._currentPageDatas,
        total
      }
    } catch (e) {
      return false
    }
  }
  setOnLoadingChange(lisn){
    this._request.setOnLoadingChange(lisn)
    return this
  }
  /**
   * 修改参数
   * @param params
   */
  addParams(params) {
    this._request.addParams(params)
    return this
  }

  /**
   * 修改参数
   * @param data
   */
  addData(data) {
    this._request.addData(data)
    return this
  }

  /**
   * 重置
   * @private
   */
  _reset() {
    this._current_page = 1 // 第几页
    this._totalDatas = [] // 总数据
    this._currentPageDatas = [] // 当前数据
    this._total = 0 // 总条数
  }
}
