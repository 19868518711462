import BaseRes from "@/ag/entities/res/BaseRes";

export default class FactoryItemRes extends BaseRes {

      id = '' // id
      name = '' // 名称
      cover = '' // 封面
      merchant_phone = '' // 商户号
      contact = '' // 联系人
      contact_phone = '' // 联系人电话
      status = '' // 状态
      status_str = '' // 状态str
      employee_name = '' // 专属客服
  
  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }
}