import UiKit from "@/ag/kits/UiKit";

export default {
  mixins: [],
  data() {
    return {
      redirect:''
    }
  },
  created() {
    console.log('this.$route',this.$route)
    const {redirect} = this.$route.query
    this.redirect = redirect
    console.log("redirect1", redirect)
  },
  methods:{
    navHome(){
      if (this.redirect){
        UiKit.nav(this.redirect)
      }else{
        UiKit.navHome()
      }
    }
  }
}
