<template>
  <div class="ag-flex-row-center ag-detail-item" >
    <span class="text- ag-detail-item-label" :style="{width:labelWidth}">{{label}}</span>
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'AgDetailItem',
  props: {
    label: {
      type: [String],
      default: ''
    },
    labelWidth: {
      type: [Number,String],
      default: "100px"
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped>
.ag-detail-item{
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 26px;
}
.ag-detail-item-label{
  color: #909399;
  font-size: 14px;
  text-align: right;
  padding: 0 12px 0 0;
}

</style>
