import BaseRes from "@/ag/entities/res/BaseRes";

export default class ParamsItemRes extends BaseRes {

      id = '' // 自增id
      mini_id = '' // 关联小程序id
      key = '' // 键名
      desc = '' // 描述
      create_time = '' // 创建时间
  
  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }
}