<template>
  <el-date-picker
    style="overflow-x: auto;"
    :size="size"
    v-model="currentValue"
    class="filter-item"
    :type="type"
    :picker-options="pickerOptions"
    :start-placeholder="startPlaceholder"
    :end-placeholder="endPlaceholder"
    @change="handleModelInput"
  />
</template>

<script>

/**
 * 注意：
 * 与该组件绑定model数据格式是 Date[2] 第一个元素是开始时间，第二个元素是结束时间
 */
import {
  getMonthEndDate,
  getMonthStartDate,
  getPreMonthEndDate,
  getPreMonthStartDate,
  twentyThreeHoursForToday,
  zeroHoursForToday
} from '@/ag/datatime'

export default {
  name: 'AgTableFilterTime',
  props: {
    startPlaceholder: {
      type: [String],
      default: '创建时间'
    },
    endPlaceholder: {
      type: [String],
      default: ''
    },
    size: {
      type: [String],
      default: 'medium'
    },
    time: {
      type: [String],
      default: null
    },
    // year/month/date/dates/week/datetime/datetimerange/daterange
    type: {
      type: [String],
      default: 'datetimerange'
    },
    value: {
      type: [Number, String, Array],
      default: null
    }
  },
  data() {
    return {
      currentValue: this.value,
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const start = zeroHoursForToday()
              const end = twentyThreeHoursForToday()
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const start = zeroHoursForToday()
              start.setDate(start.getDate() - 1)
              const end = twentyThreeHoursForToday()
              end.setDate(end.getDate() - 1)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近7天',
            onClick(picker) {
              const start = zeroHoursForToday()
              start.setDate(start.getDate() - 7)
              const end = twentyThreeHoursForToday()
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近30天',
            onClick(picker) {
              const start = zeroHoursForToday()
              start.setDate(start.getDate() - 30)
              const end = twentyThreeHoursForToday()
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '本月',
            onClick(picker) {
              const start = getMonthStartDate()
              const end = getMonthEndDate()
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '上个月',
            onClick(picker) {
              const start = getPreMonthStartDate()
              const end = getPreMonthEndDate()
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '1月份',
            onClick(picker) {
              const start = getMonthStartDate(1)
              const end = getMonthEndDate(1)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '2月份',
            onClick(picker) {
              const start = getMonthStartDate(2)
              const end = getMonthEndDate(2)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '3月份',
            onClick(picker) {
              const start = getMonthStartDate(3)
              const end = getMonthEndDate(3)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '4月份',
            onClick(picker) {
              const start = getMonthStartDate(4)
              const end = getMonthEndDate(4)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '5月份',
            onClick(picker) {
              const start = getMonthStartDate(5)
              const end = getMonthEndDate(5)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '6月份',
            onClick(picker) {
              const start = getMonthStartDate(6)
              const end = getMonthEndDate(6)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '7月份',
            onClick(picker) {
              const start = getMonthStartDate(7)
              const end = getMonthEndDate(7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '8月份',
            onClick(picker) {
              const start = getMonthStartDate(8)
              const end = getMonthEndDate(8)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '9月份',
            onClick(picker) {
              const start = getMonthStartDate(9)
              const end = getMonthEndDate(9)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '10月份',
            onClick(picker) {
              const start = getMonthStartDate(10)
              const end = getMonthEndDate(10)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '11月份',
            onClick(picker) {
              const start = getMonthStartDate(11)
              const end = getMonthEndDate(11)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '12月份',
            onClick(picker) {
              const start = getMonthStartDate(12)
              const end = getMonthEndDate(12)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '我是占位符', onClick(picker) {
            }
          }
        ]
      }
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue
    }
  },
  methods: {
    handleModelInput(event) {
      this.$emit('input', this.currentValue)
    }
  }
}
</script>

<style scoped>

</style>
