import BaseRes from "@/ag/entities/res/BaseRes";


export default class OaConfigRes extends BaseRes {

  architectureType = []
  employeeType = []
  booleanType = []
  roleType = []
  smsType = []
  whitelistType = []

  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }
}
