<template>
  <div style="line-height:30px;font-weight: bold!important;size: 28px!important;margin-bottom: 8px!important;"><el-divider direction="vertical"></el-divider>
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'AgHeader',
  props: {
    multiple: {
      type: [Boolean],
      default: false
    },
    clearable: {
      type: [Boolean],
      default: false
    },
    placeholder: {
      type: [String],
      default: null
    },
    statusList: {
      type: [Object,Array],
      default: null
    },
    value: {
      type: [Number, String,Array],
      default: null
    },
    keyIsNum: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentValue: this.value
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue
    }
  },
  methods: {
    handleModelInput(event) {
      this.$emit('input', this.currentValue)
    },
    toKey(key) {
      if (this.keyIsNum) {
        return Number.parseInt(key)
      }else{
        return key + '';
      }

    }
  }
}
</script>

<style scoped>

</style>
