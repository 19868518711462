import IndexBaseApi from '@/api/index/IndexBaseApi'
import LoginRes from "@/entities/index/res/login/LoginRes";
import CheckAccountRes from "@/entities/index/res/login/CheckAccountRes";
import UserInfoRes from "@/entities/index/res/userinfo/UserInfoRes";
import ArchitectureItemRes from "@/entities/oa/res/architecture/ArchitectureItemRes";
import AuthRuleItemRes from "@/entities/index/res/login/AuthRuleItemRes";

export default class IndexLoginApi {
  /**
   * 校验是否开通账号
   * @param phone
   * @returns {IndexBaseApi}
   */
  static checkAccountByPhone(phone) {
    return new IndexBaseApi('/user/checkAccount', 'post')
      .setData({phone})
      .setClassName(CheckAccountRes)
  }

  /**
   * 手机密码登录
   * @param data
   * @returns {IndexBaseApi}
   */
  static loginPhoneByPassword(data) {
    return new IndexBaseApi('/user/login', 'post')
      .addHeaders({"agent-str": "pc"})
      .setData(data)
      .setClassName(LoginRes)
  }

  /**
   * 获取需要的登录的企业列表
   * @param token
   * @returns {IndexBaseApi}
   */
  static getLoginEntList(token) {
    return new IndexBaseApi('/user/architecture/list', 'get')
      .setHeaders({'ag-token': token})
      .setListItemClassName(ArchitectureItemRes)
  }

  /**
   * 通过企业登录
   * @param token
   * @param architecture_id
   * @returns {IndexBaseApi}
   */
  static loginByEnt(token, architecture_id) {
    return new IndexBaseApi('/user/architecture/choose', 'post')
      .setHeaders({'ag-token': token})
      .setData({architecture_id})
  }

  static getUserInfo(token){
    return new IndexBaseApi('/user/info', 'get')
      .setHeaders({'ag-token': token})
      .setClassName(UserInfoRes)
  }
  static logout(){
    return new IndexBaseApi('/user/logout', 'post')
  }

  /**
   * 获取用户权限数
   * @returns {IndexBaseApi}
   */
  static getAuthRuleTreeTop(){
    return new IndexBaseApi('/user/auth/rule','get')
      .setListItemClassName(AuthRuleItemRes)
  }

  /**
   * 获取用户权限数
   * @param id
   * @returns {IndexBaseApi}
   */
  static getAuthRuleTree(id=null){
    return new IndexBaseApi('/user/auth/rule','get')
      .setParams({id})
      .setClassName(AuthRuleItemRes)
  }

  /**
   * 检验接口权限
   * @param apis
   * @returns {IndexBaseApi}
   */
  static hitApisAuth(apis=[]){
    return new IndexBaseApi('/user/auth/rule/hit','post')
      .setData({apis})
  }
}
