import BaseRes from "@/ag/entities/res/BaseRes";

export default class CompanyItemRes extends BaseRes {

  id = '' // id
  name = '' // 企业名称
  type = '' // 企业类型
  type_str = '' // 企业类型
  logo = '' // 企业log
  shop_name = '' // 店铺名称
  contact = '' // 联系人
  contact_mobile = '' // 联系人电话
  region = '' // 所属区域
  region_str = '' // 所属区域
  business_nature = '' // 经营性质
  business_nature_str = '' // 经营性质
  cooperative_purpose = '' // 合作意向：1A2B3C
  cooperative_purpose_str = '' // 合作意向
  address_ids = '' // 所在地区，如:省id,市id,区id
  province = '' // 省份
  city = '' // 城市
  area = '' // 区
  address = '' // 详细地址
  major_stone = '' // 主营石材
  business_license = '' // 营业执照
  create_time = '' // 创建时间
  attaches = '' // 企业图片
  videos = '' // 企业视频
  cdn_url = '' // 阿里云oss_url
  city_str = '---'

  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
    this.city_str = `${this.province??'-'}\\${this.city??'-'}\\${this.area??'-'}`
  }
}
