import BaseRes from "@/ag/entities/res/BaseRes";
import {toClass} from "@/ag/utils";
import ArchitectureItemRes from "@/entities/oa/res/architecture/ArchitectureItemRes";


export default class ArchitectureDetailRes extends BaseRes {
  id = '' // id
  title = '' // 部门名称
  pid = '' // 上级部门
  type = '' // 类型 //
  introduction = '' // 简介
  tel = '' // 电话
  weigh = '' // 权重
  manager_employee = [] // 部门主管
  employee_count = '' // 部门员工数

  parent={} // 父组织

  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
    this.parent = toClass(this.parent,ArchitectureItemRes)
  }
}
