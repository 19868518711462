<template>
  <div>
    <el-radio-group v-model="currentValue" size="medium" @change="onChange">
      <el-radio style="margin-bottom: 4px;" v-for="(item,i) in selectList" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
    </el-radio-group>
  </div>
</template>

<script>

export default {
  name: 'AgFormRadio',
  props: {
    selectList: {
      type: Array,
      default: () => {
        return [{label: '是', value: 2}, {label: '否', value: 1}]
      }
    },
    value: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      currentValue: this.value
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue
    }
  },
  methods: {
    onChange(event) {
      this.$emit('input', this.currentValue)
      this.$emit('onChange')
    }
  }
}
</script>

<style scoped>

</style>
