import BaseRes from "@/ag/entities/res/BaseRes";

export default class ShoppingNavItemRes extends BaseRes {

  id = '' // id
  name = '' // 名称
  cover = '' // 封面
  status = '' // 状态 1下架 2上架
  weight = '' // 排序
  create_time = '' // 创建时间

  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }
}