<template>
  <el-container>
    <el-header class="app-layout-header">
      <AppBar/>
    </el-header>
    <el-container>
      <el-aside class="app-layout-aside" width="200px">
        <AppMenu/>
      </el-aside>
      <el-main class="app-layout-main">
        <router-view/>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import AppBar from "@/components/layout/App/AppBar";
import AppMenu from "@/components/layout/App/AppMenu";

export default {
  name: "AppLayout",
  components: {AppMenu, AppBar}
}
</script>

<style scoped lang="scss">
.app-layout-header {
  width: 100%;
  height: 44px !important;
  background-color: #fdfdfd;
  z-index: 9;
  border-bottom: solid 1px #eeeeee;
  position: fixed;
}

.app-layout-aside{
  margin-top: 44px;
  width: 200px;
  height: calc(100vh - 44px);
  background-color: white;
}
.app-layout-main {
  width: 100%;
  margin: 44px 1px 0 1px;
  padding: 0;
  background-color: white;
  height: calc(100vh - 44px);
}
</style>
