import OaBaseApi from '@/api/oa/OaBaseApi'
import ArchitectureTreeRes from '@/entities/oa/res/architecture/ArchitectureTreeRes'
import ArchitectureTopRes from '@/entities/oa/res/architecture/ArchitectureTopRes'
import ArchitectureItemRes from '@/entities/oa/res/architecture/ArchitectureItemRes'
import ArchitectureDetailRes from "@/entities/oa/res/architecture/ArchitectureDetailRes";

export default class OaArchitectureApi {
  /**
   * 获取部门树形结构数据
   * @returns {OaBaseApi}
   */
  static getArchitectureTree() {
    return new OaBaseApi('/company/architecture/treeList')
      .setClassName(ArchitectureTreeRes)
  }
  /**
   * 获取部门子部门列表
   * @returns {OaBaseApi}
   */
  static getArchitectureChildren(id) {
    return new OaBaseApi('/company/architecture/list')
      .setParams({id})
      .setListItemClassName(ArchitectureItemRes)
  }
  /**
   * 新建部门
   * @returns {OaBaseApi}
   */
  static create(data) {
    return new OaBaseApi('/company/architecture/create', 'post')
      .setData(data)
      .setClassName(ArchitectureItemRes)
  }
  /**
   * 更新部门
   * @returns {OaBaseApi}
   */
  static update(data) {
    return new OaBaseApi('/company/architecture/update', 'post')
      .setData(data)
      .setClassName(ArchitectureItemRes)
  }

  /**
   * 详情
   * @returns {OaBaseApi}
   */
  static detail(id) {
    return new OaBaseApi('/company/architecture/detail', 'get')
      .setParams({id})
      .setClassName(ArchitectureDetailRes)
  }
  /**
   * 获取根部门
   * @returns {OaBaseApi}
   */
  static getTopArchitecture(id=0) {
    return new OaBaseApi('/company/architecture/getTop')
      .setParams({id})
      .setClassName(ArchitectureTopRes)
  }
  /**
   * 获取部门全路径
   * @param id 部门id
   * @returns {OaBaseApi}
   */
  static getArchitectureBreadcrumb(id) {
    return new OaBaseApi('/company/architecture/getBreadcrumb')
      .setParams({id})
      .setListItemClassName(ArchitectureItemRes)
  }
  /**
   * 通过部门ids获取部门列表
   * @param ids 部门id
   * @returns {OaBaseApi}
   */
  static getArchitectureListByIds(ids) {
    return new OaBaseApi('/company/architecture/getListByIds', 'post')
      .setData({ids})
      .setListItemClassName(ArchitectureItemRes)
  }
  /**
   * 删除部门
   * @returns {OaBaseApi}
   */
  static remove(ids) {
    return new OaBaseApi('/company/architecture/remove', 'post')
      .setData({ids})
  }
}

