import BaseRes from "@/ag/entities/res/BaseRes";

export default class SwConfigRes extends BaseRes {

  stoneType = []//石材企业类型
  region = []//所属区域
  businessNature = []//经营性质
  cooperativePurpose = []//合作意向
  deviceType = []//设备类型
  stoneLevel = []//设备类型
  slabType = []//板材类型

  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }
}
