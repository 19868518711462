import AsyBaseApi from "@/api/asy/AsyBaseApi.js";
import PagingKit from "@/ag/kits/PagingKit";
import ProductSpecsTypeItemRes from "@/entities/asy/res/products/specsType/ProductSpecsTypeItemRes";
import ProductSpecsTypeDetailRes from "@/entities/asy/res/products/specsType/ProductSpecsTypeDetailRes";

export default class ProductSpecsTypeApi {
  /**
   * 属性分页
   * @param data
   * @returns { AsyBaseApi }
   */
  static page(data = {}) {
    return new AsyBaseApi('/products/specsType/page', 'post')
      .setData(data)
      .setPageItemClassName(ProductSpecsTypeItemRes)
  }

  /**
   * 获取分页
   * @param data
   * @returns {PagingKit}
   */
  static paging(data) {
    return new PagingKit(ProductSpecsTypeApi.page(data), 10)
  }

  /**
   * 获取详情
   * @param id
   * @returns { AsyBaseApi }
   */
  static detail(id) {
    return new AsyBaseApi('/products/specsType/detail', 'get')
      .setParams({id})
      .setClassName(ProductSpecsTypeDetailRes)
  }

  /**
  * 保存
  * @param data
   * @returns { AsyBaseApi }
   */
  static update(data) {
    return new AsyBaseApi('/products/specsType/update', 'post')
      .setData(data)
      .setClassName(ProductSpecsTypeDetailRes)
  }

  /**
   * 创建
   * @param data
   * @returns { AsyBaseApi }
   */
  static create(data) {
    return new AsyBaseApi('/products/specsType/create', 'post')
      .setData(data)
      .setClassName(ProductSpecsTypeDetailRes)
  }

  /**
   * 删除
   * @param ids
   * @returns { AsyBaseApi }
   */
  static remove(ids) {
    return new AsyBaseApi('/products/specsType/remove', 'post')
      .setData({ids})
  }

  /**
   * 获取模板
   * @param classId
   * @returns {AsyBaseApi}
   */
  static getSpecsByClass(classId) {
    return new AsyBaseApi('/products/specsType/getSpecsByClass', 'post')
      .setData({classId})
  }
}
