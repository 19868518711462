import CacheKit from "@/ag/kits/CacheKit";
import OaCommonApi from "@/api/oa/OaCommonApi";
import AsyCommonApi from "@/api/asy/AsyCommonApi";

export default class Oss {
  static async getOssSignature(app='oa') {
    let key = `oss-key-${app}`
    let signature = CacheKit.get(key)
    if (signature){
      signature = JSON.parse(signature)
      let now = (new Date().getTime()) / 1000
      console.log('signature.expire=',signature.expire)
      console.log('getOssSignature now=',now)
      if (signature.expire > now) { // 还有效
        return signature
      }
    }
    // 清除缓存
    CacheKit.set(key,'')
    try {
      let res = {}
      if (app == 'asy'){
        res = await AsyCommonApi.getOssSignature(app).exec()
      }else{
        res = await OaCommonApi.getOssSignature(app).exec()
      }
      CacheKit.set(key,JSON.stringify(res))
      return res
    } catch (e) {
      console.error('getOssSignature',e)
      return null
    }
  }
}
