export const COMMIT_ACTIONS = {
  GET_USER_INFO: 'GET_USER_INFO',  // 获取用户信息
  SET_USER_INFO: 'SET_USER_INFO',  // 设置用户信息
  SET_TAB: 'SET_TAB', // 设置工作台
  SET_APP: 'SET_APP', // 设置应用
  SET_APP_ID: 'SET_APP_ID', // 设置应用ID
  SET_OA_CONFIG: 'SET_OA_CONFIG', // 设置oa配置
  SET_SW_CONFIG: 'SET_SW_CONFIG', // 设置sw配置
  SET_ASY_CONFIG: 'SET_ASY_CONFIG', // 设置asy配置
  SET_AD_CONFIG: 'SET_AD_CONFIG', // 设置ad配置
  SET_MERCHANT_CONFIG: 'SET_MERCHANT_CONFIG', // 设置merchant配置
  SET_PROJECT_CONFIG: 'SET_PROJECT_CONFIG', // 设置工程配套配置
}



