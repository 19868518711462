<script>
export default {
  name: 'AgDialogBody',
  props: {
    dialog: {type: Object, default:()=>{return {}}},
  },
  render(createElement) {
    if (this.dialog.component){
      return createElement(this.dialog.component,{
        props:{
          dialog:this.dialog
        },
      })
    }
    return createElement('h1',`action:${this.action}未设置dialog`)
  },
  created() {
  }
}
</script>

