import {COMMIT_ACTIONS} from "@/store/types";

export default {
  [COMMIT_ACTIONS.SET_TAB](state, v) {
    state.tabType = v
  },
  [COMMIT_ACTIONS.SET_USER_INFO](state, {userInfo, token}) {
    state.userInfo = userInfo
    state.token = token
  },
  [COMMIT_ACTIONS.SET_OA_CONFIG](state, oaConfig) {
    state.oaConfig = oaConfig
  },
  [COMMIT_ACTIONS.SET_APP](state, app) {
    state.app = app
  },
  [COMMIT_ACTIONS.SET_APP_ID](state, appId) {
    state.appId = appId
  }
}
