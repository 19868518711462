import AdBaseApi from "@/api/ad/AdBaseApi";
import AdConfigRes from "@/entities/ad/res/common/AdConfigRes";

export default class AdCommonApi {
  /**
   * 获取ad全局配置
   * @returns {AdBaseApi}
   */
  static getConfig() {
    return new AdBaseApi('/common/getMacroConfig')
      .setClassName(AdConfigRes)
  }
}

