<template>
  <div class="ag-drawer-normal">
    <div class="ag-drawer-header">{{ drawer.preload.title }}</div>
    <div class="ag-drawer-body">
      <el-row>
        <el-col :xs="24" :sm="12">
          <AgDetailItem label="ID">{{ detail.id }}</AgDetailItem>
        </el-col>
        <el-col :xs="24" :sm="12">
          <AgDetailItem label="产品分类ID">{{ detail.class_id }}</AgDetailItem>
        </el-col>
        <el-col :xs="24" :sm="12">
          <AgDetailItem label="类型名称">{{ detail.name }}</AgDetailItem>
        </el-col>
        <el-col :xs="24" :sm="12">
          <AgDetailItem label="状态">{{ detail.status }}</AgDetailItem>
        </el-col>
        <el-col :xs="24" :sm="12">
          <AgDetailItem label="图片">{{ detail.is_img }}</AgDetailItem>
        </el-col>
        <el-col :xs="24" :sm="12">
          <AgDetailItem label="排序">{{ detail.weight }}</AgDetailItem>
        </el-col>
        <el-col :xs="24" :sm="12">
          <AgDetailItem label="软删除">{{ detail.delete_time }}</AgDetailItem>
        </el-col>
        <el-col :xs="24" :sm="12">
          <AgDetailItem label="创建时间">{{ detail.create_time }}</AgDetailItem>
        </el-col>
      </el-row>
    </div>
    <div class="ag-drawer-footer">
      <el-button size="normal" @click="agDrawerClose">关闭</el-button>
    </div>
  </div>
</template>

<script>
import Index from './index'
import FormEmployeeInput from "@/components/oa/form/form-employee-input";
import AgDetailItem from "@/ag/components/AgDetail/AgDetailItem";

export default {
  name: 'ProductSpecsTypeDetail',
  components: {AgDetailItem, FormEmployeeInput},
  mixins:[Index]
}
</script>

<style scoped lang="sass">
@import "index"
</style>
