import {BasePageMixin} from "@/mixins/BasePageMixin";
import ProductSpecsTypeDetailRes from "@/entities/merchant/res/products/specsType/ProductSpecsTypeDetailRes";
import ProductSpecsTypeApi from "@/api/merchant/products/specsType/ProductSpecsTypeApi";
import {DrawerMixin} from "@/ag/mixins/components/DrawerMixin";


export default {
  mixins: [BasePageMixin,DrawerMixin],
  props: {},
  data() {
    return {
      detail:new ProductSpecsTypeDetailRes()
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      const {id} = this.agGetPreload()
      ProductSpecsTypeApi.detail(id).exec().then((res) => {
        this.detail = res
      })
    },
  }
}
