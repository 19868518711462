<script>
export default {
  name: 'AgDrawerBody',
  props: {
    drawer: {type: Object, default:{}},
  },
  render(createElement) {
    if (this.drawer.component){
      return createElement(this.drawer.component,{
        props:{
          drawer:this.drawer
        },
      })
    }
    return createElement('h1',`action:${this.action}未设置drawer`)
  },
  created() {
  }
}
</script>

