import Vue from 'vue'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
Vue.use(VueViewer,{
  defaultOptions: {
    zIndex: 3000
  }
})


