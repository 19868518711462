import IndexLoginApi from "@/api/index/IndexLoginApi";
import CacheKit from "@/ag/kits/CacheKit";
import {CacheKey} from "@/consts/CacheKey";
import {COMMIT_ACTIONS} from "@/store/types";
import UiKit from "@/ag/kits/UiKit";


export default class Auth {

  /**
   * 尝试登录
   * @returns {boolean}
   */
  static async tryLogin(query) {
    try {
      // 已经是登录态了
      if ($store.state.global.token) {
        return true
      }
      const token = CacheKit.get(CacheKey.TOKEN)
      if (!token) {
        return false
      }
      try {
        const userInfo = await IndexLoginApi.getUserInfo(token).exec()
        $store.commit(COMMIT_ACTIONS.SET_USER_INFO, {userInfo, token})
        Auth.getAppMenu(query)
        return true
      } catch (e) {
        CacheKit.set(CacheKey.TOKEN, '')
        console.error(e)
        return false
      }
    } catch (e) {
      return false
    }
  }

  /**
   * 退出登录
   */
  static logout() {
    IndexLoginApi.logout().exec()
    CacheKit.set(CacheKey.TOKEN, '')
    $store.commit(COMMIT_ACTIONS.SET_USER_INFO, {})
    return UiKit.nav2Login()
  }

  static getAppMenu(query) {
    let {appId} = query
    if (appId) {
      $store.commit(COMMIT_ACTIONS.SET_APP_ID, appId)
      IndexLoginApi.getAuthRuleTree(appId).exec().then((res) => {
        $store.commit(COMMIT_ACTIONS.SET_APP, res)
      })
    }
  }
}
