import BaseRes from "@/ag/entities/res/BaseRes";


export default class RuleDetailRes extends BaseRes {

  id = '' // id
  pid = '' // 父ID
  title = '' // 规则名称
  icon = '' // 图标
  logo = '' // logo
  show_type = '' // 显示隐藏
  show_type_str = '' // 显示类型
  app_type = '' // 规则类型
  app_type_str = '' // 规则类型
  web_url = '' // 后台链接
  h5_url = '' // 前端链接
  api_url = '' // api接口链接
  weigh = '' // 权重
  remark = '' // 备注
  create_time = '' // 创建时间

  whitelistType = []

  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }
}
