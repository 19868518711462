import BaseRes from "@/ag/entities/res/BaseRes";

export default class EmployeeBaseRes extends BaseRes{
  id = '' // id
  name = '' // 姓名

  constructor(data) {
    super();
    this.initProp(data)
  }
  onAfterSetProp() {
  }
}
