import {BaseMixin} from "@/ag/mixins/BaseMixin";

export const DialogMixin = {
  mixins:[BaseMixin],
  props: {
    dialog: { // 携带的信息
      type: Object,
      default: () => {
        return {
          action:'',
          preload:{}
        }
      },
    }
  },
  data(){
    return {
      agAction:''
    }
  },
  created() {
    this.agAction = this.agGetAction()
  },
  methods: {
    // action
    agGetAction(){
      const {action} = this.dialog
      return action
    },
    // 附加信息
    agGetPreload(){
      const {preload} = this.dialog
      return preload
    },
    // 发送抽屉事件
    agDialogDispatchAction(data={}) {
      this.$parent.$emit('onDialogAction', {action:this.agGetAction(),dialog:this.dialog, data})
    },
    // 关闭
    agDialogClose() {
      this.$parent.$emit('onDialogClose')
    },
  }
}
