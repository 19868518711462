<template>
  <el-drawer
    :visible.sync="inVisible"
    :show-close="drawerStyle.showClose"
    :append-to-body="drawerStyle.appendToBody"
    :close-on-press-escape="drawerStyle.closeOnPressEscape"
    :close-on-click-moda="drawerStyle.closeOnClickModal"
    :modal="drawerStyle.modal"
    :withHeader="false"
    :custom-class="drawerStyle.drawerClass"
    :direction="drawerStyle.direction">
    <ag-drawer-body @onDrawerClose="onDrawerClose" @onDrawerAction="onDrawerAction" v-if="inVisible"
                    :drawer="drawer"></ag-drawer-body>
  </el-drawer>
</template>
<script>

import AgDrawerBody from "@/ag/components/AgDrawer/body";

export default {
  name: 'AgDrawer',
  components: {AgDrawerBody},
  props: {
    visible: {type: Boolean, default: false}, // 显示
    drawer: {
      type: Object, default: () => {
        return {}
      }
    },
  },
  data: function () {
    return {
      inVisible: this.visible,
      drawerStyle: {
        drawerClass:'ag-drawer-default-class',
        direction: "rtl", // 方向
        closeOnClickModal: true, // 点击遮罩是否关闭
        closeOnPressEscape: false, // esc 是否关闭
        appendToBody: false,
        showClose: false,
        modal:true
      }
    }
  },
  created() {
  },
  watch: {
    visible(val) {
      this.inVisible = val
    },
    inVisible(val) {
      this.$emit('update:visible', val);
    },
    drawer(val) {
      this.drawer = val
      this.drawerStyle = {...this.drawerStyle, ...this.drawer.drawerStyle}
    },
  }
  , methods: {
    onDrawerClose() {
      this.inVisible = false
    },
    onDrawerAction({action,data,drawer}) {
      this.$emit('onDrawerAction', {action,data,drawer})
    }
  }
}
</script>

