import { shallowClone } from '@/ag/utils'

export default class BaseRes {
  // 设置属性之前
  onBeforeSetProp(data) {
    return data
  }

  initProp(data) {
    shallowClone(this, this.onBeforeSetProp(data))
    this.onAfterSetProp()
    return this
  }

  // 设置属性之后
  onAfterSetProp() {

  }


}
