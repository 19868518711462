import { Tabs } from '@/consts/Tabs'
import {BaseMixin} from "@/ag/mixins/BaseMixin";
import UiKit from "@/ag/kits/UiKit";

export default {
  mixins:[BaseMixin],
  props:{
    active:{
      type:String,
      default:''
    }
  },
  data(){
    return {
      tabs:Tabs.getTabs(),
      logout:Tabs.LOGOUT,
      workbench:Tabs.WORKBENCH
    }
  },
  methods:{
    onLogoutClick(){
      UiKit.alert('确定要退出吗','提示').then(()=>{
        this.agNavUrl(this.logout.url)
      })
    },
  }
}
