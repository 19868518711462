<template>
  <div>
    <img src="@/assets/logo-text.png"/>
  </div>
</template>

<script>
export default {
  name: 'LogoBar'
}
</script>

<style scoped>

</style>
