import PagesItemRes from "@/entities/applets/res/pages/PagesItemRes";

export default class PagesDetailRes extends PagesItemRes {

      id = '' // 自增id
      mini_id = '' // 关联小程序id
      path = '' // 页面路径
      name = '' // 名称
      create_time = '' // 创建时间
  
  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }
}