/**
 * 环境变量
 */
const APIS = {
  INDEX: process.env.VUE_APP_API_INDEX,
  OA: process.env.VUE_APP_API_OA,
  ASY: process.env.VUE_APP_API_ASY,
  COCKPIT: process.env.VUE_APP_API_COCKPIT,
  SW: process.env.VUE_APP_API_SW,
  AD: process.env.VUE_APP_API_AD,
  MERCHANT: process.env.VUE_APP_API_MERCHANT,
  AD_PRE_BASE_URL: process.env.VUE_APP_API_AD_PRE_BASE_URL,
  SLAB: process.env.VUE_APP_API_SLAB,
}

export class Env {
  static AD_PRE_BASE_URL =  process.env.VUE_APP_API_AD_PRE_BASE_URL
  static IS_TEST = process.env.NODE_ENV==='development'
  static getApi(module) {
    module = module.toUpperCase()
    return APIS[module]
  }
}


