import SwBaseApi from '@/api/sw/SwBaseApi'
import SwConfigRes from "@/entities/sw/res/common/SwConfigRes";

export default class SwCommonApi {
  /**
   * 获取板材配置
   * @returns {SwBaseApi}
   */
  static getConfig() {
    return new SwBaseApi('/common/getMacroConfig', 'get')
      .setPageItemClassName(SwConfigRes)
  }
}

