<template>
  <el-menu :router="!isGroup" :default-active="activeMenu" :default-openeds="opens" unique-opened @select="onItemSelect">
    <template v-for="menu in menus">
      <!--有子菜单时-->
      <el-submenu v-if="menu.children" :index="getRoute(menu)">
        <template slot="title">
          <el-image class="app-icon" :src="menu.logo"/>
          <span>{{ menu.title }}</span>
        </template>
        <el-menu-item v-for="subMenu in menu.children" :index="getRoute(subMenu)" :key="subMenu.id"
                      :route="getRouteObj(subMenu)"
        >
          <el-image class="app-icon" :src="subMenu.logo"/>
          <span slot="title">{{ subMenu.title }}</span>
        </el-menu-item>
      </el-submenu>
      <!--只有一个子菜单时-->
      <el-menu-item v-else-if="menu.single" :index="getRoute(menu.singleMenu)"
                    :route="getRouteObj(menu)"
      >
        <el-image class="app-icon" :src="menu.singleMenu.logo"/>
        <span slot="title">{{ menu.singleMenu.title }}</span>
      </el-menu-item>
      <!--没有子菜单-->
      <el-menu-item v-else :index="getRoute(menu)"
                    :route="getRouteObj(menu)"
      >
        <el-image class="app-icon" :src="menu.logo"/>
        <span slot="title">{{ menu.title }}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>
<script>
import AppMenu from './menu'

export default {
  mixins: [AppMenu],
  name: 'AppMenu',
}
</script>

<style scoped>
>>> .el-menu-item.is-active {
  background-color: rgba(252, 238, 229, 0.6);
}

.app-icon {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}
</style>
