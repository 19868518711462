
import { DrawerMixin } from '@/ag/mixins/components/DrawerMixin'
import { FormMixin } from '@/ag/mixins/FormMixin'
import { DialogPageMixin } from '@/mixins/DialogPageMixin'
import PagesDto from "@/entities/applets/dto/pages/PagesDto";
import { PAGES_ACTIONS } from "@/pages/applets/views/pages/Pages/macros";
import PagesApi from "@/api/applets/pages/PagesApi";
import UiKit from "@/ag/kits/UiKit";
import {BasePageMixin} from "@/mixins/BasePageMixin";

export default {
  mixins: [DrawerMixin, FormMixin, DialogPageMixin,BasePageMixin],
  props: {},
  data() {
    return {
      agForm: new PagesDto(),
      id : ''
    }
  },
  created() {
    let {id,mini_id} = this.agGetPreload()
    this.agForm.mini_id = mini_id
    this.id = id
    if (this.agAction === PAGES_ACTIONS.PAGES_EDIT){
      this.agIsEdit = true
      this.getDetail()
    }
  },
  methods: {
    getDetail(){
      PagesApi.detail(this.id).setOnLoadingChange(this.onAgSubmitChange.bind(this)).exec().then((res)=>{
        this.agForm = new PagesDto(res)
      })
    },
    onSubmit(){
      this.agValidate().then(()=>{
        if (this.agAction === PAGES_ACTIONS.PAGES_EDIT){
          PagesApi.update(this.agForm.toDto()).setOnLoadingChange(this.onAgSubmitChange.bind(this)).exec().then((res)=>{
            this.agDrawerDispatchAction()
            this.agDrawerClose()
            UiKit.success('保存成功')
          })
        }else{
          PagesApi.create(this.agForm.toDto()).setOnLoadingChange(this.onAgSubmitChange.bind(this)).exec().then((res)=>{
            this.agDrawerDispatchAction()
            this.agDrawerClose()
            UiKit.success('添加成功')
          })
        }
      })
    }
  }
}
