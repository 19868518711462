import {BaseDto} from "@/ag/entities/dto/BaseDto";

export default class NoticeDto extends BaseDto{

    id = '' // 自增id
    gh_id = '' // 原始id
    app_id = '' // app_id
    name = '' // 名称
    type = ''
    app_key = '' // app_key
    app_secret = '' // app_secret
    create_time = '' // 创建时间

  $rules = {
    gh_id : this.required('必填'),
    app_id : this.required('必填'),
    name : this.required('必填'),
    type : this.required('必填'),
    app_key : this.required('必填'),
    app_secret : this.required('必填'),
  }
  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }

  toDto() {
    let dto = super.toDto();
    return dto
  }
}
