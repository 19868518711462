import {BasePageMixin} from "@/mixins/BasePageMixin";
import PagesDetailRes from "@/entities/applets/res/pages/PagesDetailRes";
import PagesApi from "@/api/applets/pages/PagesApi";
import {DrawerMixin} from "@/ag/mixins/components/DrawerMixin";


export default {
  mixins: [BasePageMixin,DrawerMixin],
  props: {},
  data() {
    return {
      detail:new PagesDetailRes()
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      const {id} = this.agGetPreload()
      PagesApi.detail(id).exec().then((res) => {
        this.detail = res
      })
    },
  }
}
