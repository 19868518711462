import ProductSpecsTypeItemRes from "@/entities/merchant/res/products/specsType/ProductSpecsTypeItemRes";

export default class ProductSpecsTypeDetailRes extends ProductSpecsTypeItemRes {

  id = '' // ID
  class_id = '' // 产品分类ID
  name = '' // 类型名称
  status = '' // 状态
  is_img = '' // 图片
  weight = '' // 排序
  delete_time = '' // 软删除
  create_time = '' // 创建时间

  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }
}