import ResultKit from "./ResultKit";
import {toClass} from "@/ag/utils";

const axios = require('axios').default

export default class HttpKit {
  static TIMEOUT = 15 * 1000

  /**
   * 设置全局的headers一般用于带登录token
   * @param headers
   */
  static SetGlobalHeaders(headers) {
    axios.defaults.headers.common = {...axios.defaults.headers.common, ...headers}
  }

  /**
   * 普通GET
   * @param url
   * @param params
   * @param headers
   * @returns {Promise<AxiosResponse<any>>}
   * @constructor
   */
  static GET(url, params = {}, headers = {}) {
    return this.REQUEST(url, params, {}, 'get', headers)
  }

  /**
   * 普通POST
   * @param url
   * @param data
   * @param headers
   * @returns {Promise<AxiosResponse<any>>}
   * @constructor
   */
  static POST(url, data = {}, headers = {}) {
    return this.REQUEST(url, {}, data, 'post', headers)
  }

  /**
   * 请求
   * @param url
   * @param params
   * @param data
   * @param method
   * @param headers
   * @returns {Promise<unknown>}
   * @constructor
   */
  static REQUEST(url, params = {}, data = {}, method = 'get', headers = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method, url, params, data, headers,
        timeout: this.TIMEOUT
      })
        .then(this._transRes(resolve, reject))
        .catch(this._transErr(reject))
    })
  }

  /**
   * result => class
   * @param className
   * @returns {function({data?: *}): *}
   */
  static toResClass(className) {
    return ({data}) => {
      return toClass(data, className)
    }
  }

  /**
   * result => class list
   * @param className
   * @returns {(function({data?: *}): ([]|*[]))|*}
   */
  static toListResClass(className) {
    return ({data}) => {
      if (!data) {
        return []
      }
      let list = []
      for (const item of data) {
        list.push(toClass(item, className))
      }
      return list
    }
  }
  /**
   * 分页
   * result => class page
   * @param className
   * @returns {(function({data?: *}): ([]|*[]))|*}
   */
  static toPageResClass(className) {
    return ({data}) => {
      if (!data) {
        return {}
      }
      if (!data.list){
        return data
      }
      let list = []
      for (const item of data.list) {
        list.push(toClass(item, className))
      }
      data.list = list
      return data
    }
  }

  // 转换成ResultKit
  static _transRes(resolve, reject) {
    return ({status, statusText, data}) => {
      if (status === 200) {
        let {code, msg} = data
        if (code === 1) {
          return resolve(ResultKit.OK(data.data, msg))
        } else {
          return reject(ResultKit.Failed(msg, code, data.data))
        }
      } else {
        return reject(ResultKit.Failed(statusText, status))
      }
    }
  }

  // 转换成ResultKit
  static _transErr(reject) {
    return (err) => {
      let {message} = err
      if (message === 'Request failed with status code 404') {
        reject(ResultKit.Failed('404找不到内容~', 404))
      } else if (message === 'Network Error') {
        reject(ResultKit.Failed('网络出错了~', -2))
      } else {
        reject(ResultKit.Failed('操作失败~'))
      }
    }
  }

}
