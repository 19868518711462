import NoticeForm from "@/pages/applets/views/notice/Notice/components/drawers/NoticeForm/NoticeForm";
import NoticeDetail from "@/pages/applets/views/notice/Notice/components/drawers/NoticeDetail/NoticeDetail";
import PagesList from "@/pages/applets/views/pages/Pages/PagesList/PagesList";
import ParamsList from "@/pages/applets/views/params/Params/ParamsList/ParamsList";

export const NOTICE_ACTIONS = {
  NOTICE_ADD: 'NOTICE_ADD',
  NOTICE_EDIT: 'NOTICE_EDIT',
  NOTICE_DETAIL: 'NOTICE_DETAIL',
  PAGES: 'PAGES',
  PARAMS: 'PARAMS',
}
export const NOTICE_DRAWERS = {
  NOTICE_ADD: {
    action: NOTICE_ACTIONS.NOTICE_ADD,
    component: NoticeForm,
    preload: {title: '添加'},
    drawerStyle: {
      drawerClass: 'ag-drawer-mid-class'
    }
  },
  NOTICE_EDIT: {
    action: NOTICE_ACTIONS.NOTICE_EDIT,
    component: NoticeForm,
    preload: {title: '编辑'},
    drawerStyle: {
      drawerClass: 'ag-drawer-mid-class'
    }
  },
  NOTICE_DETAIL: {
    action: NOTICE_ACTIONS.NOTICE_DETAIL,
    component: NoticeDetail,
    preload: {title: '详情'},
    drawerStyle: {
      drawerClass: 'ag-drawer-mid-class'
    }
  },
  PAGES: {
    action: NOTICE_ACTIONS.PAGES,
    component: PagesList,
    preload: {title: '页面'},
    drawerStyle: {
      drawerClass: 'ag-drawer-mid-class'
    }
  },
  PARAMS: {
    action: NOTICE_ACTIONS.PARAMS,
    component: ParamsList,
    preload: {title: '参数'},
    drawerStyle: {
      drawerClass: 'ag-drawer-mid-class'
    }
  },
}
export const APPLETS_TYPE = [
  {name: '爱石艺集团OA', value: 'oa'},
  {name: '爱石艺小程序', value: 'asy'},
  {name: '爱石艺商户端', value: 'merchant'},
]
