import FactoryItemRes from "@/entities/oa/res/factory/FactoryItemRes";

export default class FactoryDetailRes extends FactoryItemRes {

    id = '' // id
    name = '' // 名称
    cover = '' // 封面
    area_ids = '' // 地区ids集合(省id,市id,区id)
    province = '' // 所在省
    city = '' // 所在城市
    district = '' // 所在区
    address_details = '' // 详细地址
    contact = '' // 联系人
    contact_phone = '' // 联系人电话
    major = '' // 主营业务
    remark = '' // 备注
    show_images = '' // 企业图片
    wechat = '' // 微信号
    create_time = '' // 创建时间
    employee_id = '' // 关联员工id(专属客服)
    status = '' // 状态
    status_str = '' // 状态str
  
  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }
}