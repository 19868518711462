import BaseRes from "@/ag/entities/res/BaseRes";

export default class ProductsClassItemRes extends BaseRes {

    id = '' // id
    name = '' // 名称
    icon = '' // 图标
    pid = '' // 父级
    status = '' // 状态；1上架，2下架
    sort = '' // 排序
    children_two_count = 0 // 二级分类个数
    goods_one_count = 0 // (一级分类)商品个数
    goods_tow_count = 0 // (二级分类)商品个数
    create_time = '' // 创建时间
    delete_time = '' // 软删除标识(删除时间)

  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }
}
