<template>
  <el-container>
    <el-header class="wb-layout-header">
      <WorkbenchBar :active="$store.state.global.tabType"/>
    </el-header>
    <el-main class="wb-layout-main">
      <router-view/>
    </el-main>
  </el-container>
</template>

<script>
import WorkbenchBar from '@/components/layout/Workbench/WorkbenchBar'
export default {
name: "WorkbenchLayout1200",
  components: { WorkbenchBar }
}
</script>

<style scoped lang="scss">
.wb-layout-header{
  width: 100%;
  min-width: 1200px;
  height: 60px !important;
  background-color: #fdfdfd;
  position: fixed;
  z-index: 9;
  border-bottom: solid 0.5px #eeeeee;
}
.wb-layout-main{
  width: 1200px;
  margin: 60px auto 0 auto;
  padding: 20px 0;
}
</style>
