<template>
  <div>
    <el-checkbox-group v-model="currentValue" size="medium" @change="onChange" :max="max" :min="min">
      <el-checkbox v-for="(item,i) in selectList" :key="item.value" :label="item.value">{{ item.label }}</el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>

export default {
  name: 'AgFormCheckBox',
  props: {
    selectList: {
      type: Array,
      default: () => {
        return []
      }
    },
    value: {
      type: [Array],
      default: null
    },
    max: {
      type: [Number,String],
      default: 3
    },
    min: {
      type: [Number,String],
      default: 0
    }
  },
  data() {
    return {
      currentValue: this.value
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue
    }
  },
  methods: {
    onChange(event) {
      console.log('this.currentValue',this.currentValue)
      this.$emit('input', this.currentValue)
    }
  }
}
</script>

<style scoped>

</style>
