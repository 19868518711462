<template>
  <div></div>
</template>

<script>
import Index from './index'

export default {
  name: 'Logout',
  mixins:[Index]
}
</script>

<style scoped lang="sass">
@import "index"
</style>
