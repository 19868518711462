<template>
  <div class="ag-drawer-normal">
    <div class="ag-drawer-header">{{ drawer.preload.title }}</div>
    <div class="ag-drawer-body">
      <el-form label-position="right" ref="agFormRef" :model="agForm" :rules="agForm.$rules" label-width="100px">
        <AgHeader>信息：</AgHeader>
        <el-row>
          <el-col :xs="24" :sm="12">
            <el-form-item label="名称" prop="name">
              <el-input v-model="agForm.name" show-word-limit :maxlength="50"/>
              <span class="ag-form-item-tip"></span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12">
            <el-form-item label="原始id" prop="gh_id">
              <el-input v-model="agForm.gh_id" show-word-limit :maxlength="50"/>
              <span class="ag-form-item-tip"></span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12">
            <el-form-item label="类型" prop="type">
              <el-select v-model="agForm.type" filterable placeholder="请选择类型" style="width:100%">
                <el-option
                  v-for="item in appTypes"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                </el-option>
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12">
            <el-form-item label="app_id" prop="app_id">
              <el-input v-model="agForm.app_id" show-word-limit :maxlength="50"/>
              <span class="ag-form-item-tip"></span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12">
            <el-form-item label="app_key" prop="app_key">
              <el-input v-model="agForm.app_key" show-word-limit :maxlength="50"/>
              <span class="ag-form-item-tip"></span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12">
            <el-form-item label="秘钥" prop="app_secret">
              <el-input v-model="agForm.app_secret" show-word-limit :maxlength="50"/>
              <span class="ag-form-item-tip"></span>
            </el-form-item>
          </el-col>
        </el-row>
<!--        <el-row>-->
<!--          <el-form-item label="图片" prop="image">-->
<!--            <AgFormUpload-->
<!--              v-model="agForm.image"-->
<!--              :multiple="false"-->
<!--              :crop="true"-->
<!--              :crop-width="600"-->
<!--              :crop-height="600"-->
<!--              tip="建议尺寸600*600，文件大小不超过100k"-->
<!--            />-->
<!--          </el-form-item>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-form-item label="富文本" prop="content">-->
<!--            <Tinymce v-model="agForm.content" app="oa" :height="500"/>-->
<!--          </el-form-item>-->
<!--        </el-row>-->
      </el-form>
    </div>
    <div class="ag-drawer-footer">
      <el-button v-if="!agGetPreload().detail" size="normal" :loading="agSubmitting" :disabled="agSubmitting" type="primary"
                 @click="onSubmit">确定
      </el-button>
      <el-button size="normal" @click="agDrawerClose">关闭</el-button>
    </div>
  </div>
</template>

<script>
import Index from './index'
import FormEmployeeInput from "@/components/oa/form/form-employee-input";
import AgHeader from "@/ag/components/AgHeader";
import AgFormUpload from "@/ag/components/AgForm/AgFormUpload";
import Tinymce from "@/ag/components/Tinymce";
import AgFormRadio from "@/ag/components/AgForm/AgFormRadio";
import AgFormSelect from "@/ag/components/AgForm/AgFormSelect";
export default {
  name: 'NoticeForm',
  components: {AgFormSelect, AgFormRadio, Tinymce, AgFormUpload, AgHeader, FormEmployeeInput},
  mixins: [Index]
}
</script>

<style scoped lang="sass">
@import "index"
</style>
