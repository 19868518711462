/**
 * 权限列表
 */
import IndexLoginApi from "@/api/index/IndexLoginApi";

export const AuthPageMixin = {
  data() {
    return {
      agAuthMapper: {},// {add:xx/xx}
      agAuth: {},// {add:true}
    }
  },
  created() {
    let apis = Object.values(this.agAuthMapper)
    if (apis.length < 1) {
      return
    }
    IndexLoginApi.hitApisAuth(apis).exec().then((res) => {
      this.hitAuth(res.data)
    })
  },
  methods: {
    hitAuth(authList) {
      let keys = Object.keys(this.agAuthMapper)
      let authObj = []
      for (const key of keys) {
        let api = this.agAuthMapper[key];
        authObj[key] = authList.includes(api)
      }
      this.agAuth = authObj
    }
  }
}

