import MerchantBaseApi from "@/api/merchant/MerchantBaseApi";
import MerchantConfigRes from "@/entities/merchant/res/common/MerchantConfigRes";

export default class MerchantCommonApi {
  /**
   * 获取merchant全局配置
   * @returns {MerchantBaseApi}
   */
  static getConfig() {
    return new MerchantBaseApi('/common/getMacroConfig')
      .setClassName(MerchantConfigRes)
  }
  static getProductClassTree() {
    return new MerchantBaseApi('/common/productClassTree ')
  }
}

