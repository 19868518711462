import OaBaseApi from '@/api/oa/OaBaseApi'
import OaConfigRes from "@/entities/oa/res/common/OaConfigRes";
import UploadSignatureRes from '@/entities/oa/res/common/UploadSignatureRes'
import RuleDetailRes from "@/entities/oa/res/common/RuleDetailRes";

export default class OaCommonApi {
  /**
   * 获取oa全局配置
   * @returns {OaBaseApi}
   */
  static getConfig() {
    return new OaBaseApi('/common/getMacroConfig')
      .setClassName(OaConfigRes)
  }

  /**
   *
   * @param appPath
   * @returns {OaBaseApi}
   */
  static getOssSignature(appPath = 'oa') {
    return new OaBaseApi('/common/getUploadSignature')
      .setParams({file_path: appPath})
      .setClassName(UploadSignatureRes)
  }

  /**
   * 获取权限-规则的复制数据
   * @param data
   * @returns {OaBaseApi}
   */
  static getCopyDetail(data) {
    return new OaBaseApi('/auth/rule/getCopyDetail', 'post')
      .setData(data)
      .setClassName(RuleDetailRes)
  }

  /**
   * 插入权限-规则的复制数据
   * @param data
   * @returns {OaBaseApi}
   */
  static updateCopyDetail(data) {
    return new OaBaseApi('/auth/rule/updateCopyDetail', 'post')
      .setData(data)
      .setClassName(RuleDetailRes)
  }

}

