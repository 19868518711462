import {BaseDto} from "@/ag/entities/dto/BaseDto";

export default class ProductSpecsTypeDto extends BaseDto{

  class_id = '' // 产品分类ID
  name = '' // 类型名称
  status = 2 // 状态
  is_img = 1 // 图片
  weight = 0 // 排序

  $rules = {
    class_id : this.required('必填'),
    name : this.required('必填'),
  }
  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }

  toDto() {
    let dto = super.toDto();
    return dto
  }
}
