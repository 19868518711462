import ParamsForm from "@/pages/applets/views/params/Params/components/drawers/ParamsForm/ParamsForm";
import ParamsDetail from "@/pages/applets/views/params/Params/components/drawers/ParamsDetail/ParamsDetail";

export const PARAMS_ACTIONS = {
  PARAMS_ADD: 'PARAMS_ADD',
  PARAMS_EDIT: 'PARAMS_EDIT',
  PARAMS_DETAIL: 'PARAMS_DETAIL',
}
export const PARAMS_DRAWERS = {
  PARAMS_ADD: {
    action: PARAMS_ACTIONS.PARAMS_ADD,
    component: ParamsForm,
    preload: {title: '添加'},
    drawerStyle: {
      drawerClass: 'ag-drawer-mid-class',
      appendToBody: true
    }
  },
  PARAMS_EDIT: {
    action: PARAMS_ACTIONS.PARAMS_EDIT,
    component: ParamsForm,
    preload: {title: '编辑'},
    drawerStyle: {
      drawerClass: 'ag-drawer-mid-class',
      appendToBody: true
    }
  },
  PARAMS_DETAIL: {
    action: PARAMS_ACTIONS.PARAMS_DETAIL,
    component: ParamsDetail,
    preload: {title: '详情'},
    drawerStyle: {
      drawerClass: 'ag-drawer-mid-class',
      appendToBody: true
    }
  }
}
