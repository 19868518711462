<template>
  <div class="app-bar ag-flex-row-center">
    <img width="30px;" src="https://cdn.oa.asymall.com/mini/logo.png"/>
    <el-link class="ag-margin-left-10" :underline="false" :href="workbench.url">{{ workbench.name }}</el-link>
    <i class="el-icon-arrow-right ag-margin-left-10"></i>
    <span class="ag-margin-left-10 ag-color-333 ag-font-13">{{$store.state.global.app.title || $store.state.appName}}</span>
  </div>
</template>

<script>
import {Tabs} from "@/consts/Tabs";

export default {
  name: 'AppBar',
  data(){
    return {
      workbench:Tabs.WORKBENCH
    }
  }
}
</script>

<style scoped lang="scss">
.app-bar{
  height: 44px;
}

</style>
