export default class CacheKit {
  static set(key, value) {
    return localStorage.setItem(key, value)
  }

  static get(key, defaultValue = '') {
    let value = localStorage.getItem(key)
    if (value === 'true') {
      value = true
    }else if (value === 'false') {
      value = false
    }
    return value ?? defaultValue
  }
}
