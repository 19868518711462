import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import {Macros} from "@/consts/Macros";
import {Env} from "@/consts/Env";

// 全局store
export const GlobalStore = {
  state: {
    macros:Macros,
    token: '',// 用户token
    userInfo: {},  // 用户信息
    tabType: '',  // 选中什么tab,
    app:{},     // 应用
    appId:{},     // 应用id
    oaConfig: {}, // OA配置
    isTest: Env.IS_TEST
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}
