<template>
  <div>
    <el-input class="ag-pointer" @click.native="onSelectEmployee" v-model="employeeNames" :style="{width: width+'px'}"
              readonly :placeholder="placeholder"/>
    <ag-dialog
      :visible.sync="agDialogData.visible"
      :dialog="agDialogData"
      @onDialogAction="onDialogAction"/>
  </div>
</template>
<script>
import AgDialog from "@/ag/components/AgDialog";
import {DialogPageMixin} from "@/mixins/DialogPageMixin";
import {SELECT_EMPLOYEE_DIALOGS} from "@/components/oa/SelectEmployee/macros";
import {getKeyValuesByArray} from "@/ag/utils";

export default {
  name: "form-employee-input",
  mixins: [DialogPageMixin],
  components: {AgDialog},
  props: {
    value: {
      type: [Number, String, Array],
      default: null
    },
    width: {
      type: [String, Number],
      default: '280'
    },
    placeholder: {
      type: String,
      default: '请选择员工'
    },
    managerEmployees: {
      type: Array,
      default: () => {
        return []
      }
    },
    dialogType: {
      type: String,
      default: () => {
        return 'single'
      }
    },
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue
    },
    managerEmployees(newValue) {
      this.selectEmployees = newValue
    }
  },
  data: () => {
    return {
      selectEmployees: []
    }
  },
  computed: {
    employeeNames() {
      let names = getKeyValuesByArray(this.selectEmployees,'name')
      return names.join(' ')
    }
  },
  methods: {
    onSelectEmployee() {
      let ids = getKeyValuesByArray(this.selectEmployees,'id')
      let dialog = this.dialogType==='multi'?SELECT_EMPLOYEE_DIALOGS.MULTI:SELECT_EMPLOYEE_DIALOGS.SINGLE
      this.agCreateDialog(dialog, {ids}, {appendToBody: true})
    },
    onDialogAction({dialog, data}) {
      let ids = null
      if (this.dialogType==='multi'){
        this.selectEmployees = data
        ids = getKeyValuesByArray(this.selectEmployees,'id')
      }else {
        this.selectEmployees = [data]
        ids = data.id
      }
      this.$emit('input', ids)
    }
  }
}
</script>

<style scoped>

</style>
