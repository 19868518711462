import {BaseMixin} from "@/ag/mixins/BaseMixin";
import UiKit from "@/ag/kits/UiKit";

export const FormMixin = {
  mixins: [BaseMixin],
  data() {
    return {
      agSubmitting: false, // 提交中,
      agIsEdit:false,
    }
  },
  methods: {
    onAgSubmitChange(loading) {
      this.agSubmitting = loading
    },
    /**
     * 校验表单
     * @param ref
     * @returns {Promise<unknown>}
     */
    agValidate(ref='agFormRef') {
      return new Promise((resolve, reject) => {
        this.$refs[ref].validate((valid) => {
          if (valid) {
            resolve()
          } else {
            UiKit.error('信息不完整，无法提交')
            reject()
          }
        })
      })
    }
  }
}
