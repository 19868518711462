<template>
  <div class="ag-drawer-normal">
    <div class="ag-drawer-header">{{ drawer.preload.title }}</div>
    <div class="ag-drawer-body">
      <el-form label-position="right" ref="agFormRef" :model="agForm" :rules="agForm.$rules" label-width="100px">
        <AgHeader>信息：</AgHeader>
        <el-row>


          <el-col :xs="24" :sm="12">
            <el-form-item label="键名" prop="key">
              <el-input v-model="agForm.key" show-word-limit :maxlength="50"/>
              <span class="ag-form-item-tip"></span>
            </el-form-item>
          </el-col>
          <el-col :xs="24">
            <el-form-item label="描述" prop="desc">
              <el-input type="textarea" v-model="agForm.desc" rows="3" :maxlength="255" show-word-limit/>
              <span class="ag-form-item-tip"></span>
            </el-form-item>
          </el-col>
        </el-row>
<!--        <el-row>-->
<!--          <el-form-item label="图片" prop="image">-->
<!--            <AgFormUpload-->
<!--              v-model="agForm.image"-->
<!--              :multiple="false"-->
<!--              :crop="true"-->
<!--              :crop-width="600"-->
<!--              :crop-height="600"-->
<!--              tip="建议尺寸600*600，文件大小不超过100k"-->
<!--            />-->
<!--          </el-form-item>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-form-item label="富文本" prop="content">-->
<!--            <Tinymce v-model="agForm.content" app="oa" :height="500"/>-->
<!--          </el-form-item>-->
<!--        </el-row>-->
      </el-form>
    </div>
    <div class="ag-drawer-footer">
      <el-button v-if="!agGetPreload().detail" size="normal" :loading="agSubmitting" :disabled="agSubmitting" type="primary"
                 @click="onSubmit">确定
      </el-button>
      <el-button size="normal" @click="agDrawerClose">关闭</el-button>
    </div>
  </div>
</template>

<script>
import Index from './index'
import FormEmployeeInput from "@/components/oa/form/form-employee-input";
import AgHeader from "@/ag/components/AgHeader";
import AgFormUpload from "@/ag/components/AgForm/AgFormUpload";
import Tinymce from "@/ag/components/Tinymce";
import AgFormRadio from "@/ag/components/AgForm/AgFormRadio";
import AgFormSelect from "@/ag/components/AgForm/AgFormSelect";

export default {
  name: 'ParamsForm',
  components: {AgFormSelect, AgFormRadio, Tinymce, AgFormUpload, AgHeader, FormEmployeeInput},
  mixins: [Index]
}
</script>

<style scoped lang="sass">
@import "index"
</style>
