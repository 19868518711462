import {deepClone} from "@/ag/utils"
import ArchitectureItemRes from "@/entities/oa/res/architecture/ArchitectureItemRes";

/**
 * 组织树
 */
export default class ArchitectureTreeRes extends ArchitectureItemRes {
  employee = [] //员工
  children = [] //子节点
  breadcrumb = [] //面包屑节点{id:"",title:""}

  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
    this.getBreadcrumb(this, [])
  }

  getBreadcrumb(architectureTree, breadcrumbs) {
    const item = {
      id: architectureTree.id,
      title: architectureTree.title,
    }
    let breadcrumb = deepClone(breadcrumbs)
    breadcrumb.push(item)
    architectureTree.breadcrumb = breadcrumb;

    if (architectureTree.children) {
      for (var i = 0; i < architectureTree.children.length; i++) {
        this.getBreadcrumb(architectureTree.children[i], breadcrumb)
      }
    }
  }
}
