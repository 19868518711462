import {BaseDto} from "@/ag/entities/dto/BaseDto";
import PartnerTaskItemRes from "@/entities/merchant/res/partner/PartnerTaskItemRes";

export default class PartnerTaskDetailRes extends PartnerTaskItemRes {

  id = '' // id
  partner_id = '' // 合作商id
  range_time = {} // 周期时间
  start_time = '' // 周期开始时间
  end_time = '' // 周期结束时间
  total_amount = '' // 任务总额度
  task_amount = '' // 任务线额度
  award_end_time = '' // 奖励结束时间
  award_rate = '' // 奖励比例
  award_more_rate = '' // 超额奖励比例

  constructor(data) {
    super();
    this.initProp(data)
  }

  onAfterSetProp() {
  }
}
