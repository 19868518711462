import {BaseMixin} from "@/ag/mixins/BaseMixin";

export default {
  mixins:[BaseMixin],
  props:{},
  data(){
    return {}
  },
  created() {
  },
  methods:{}
}
