import {DialogMixin} from "@/ag/mixins/components/DialogMixin";
import UiKit from "@/ag/kits/UiKit";
import ArchitectureApi from '@/api/oa/OaArchitectureApi'
import { deepClone } from '@/ag/utils'
import OaEmployeeApi from '@/api/oa/OaEmployeeApi'

export default {
  mixins: [DialogMixin],
  props: {
    dialog:{
      type:Object,
      default:()=>{return {}},
    }
  },
  data() {
    return {
      searchText: '',
      isMultiple: false,//多选或者单选，默认单选
      checkedAll: false,
      architectureList:[],
      employeeList:[],
      breadcrumbs:[],
      selectEmployeeData:[],
    }
  },
  created() {
    if (typeof(this.dialog.preload.type) !== 'undefined'){
      this.isMultiple = this.dialog.preload.type === "multi"
    }
    if (typeof(this.dialog.preload.ids) !== 'undefined' && this.dialog.preload.ids.length>0){
      let ids = this.dialog.preload.ids;
      this.getEmployeeListByIds(ids);
    }
    if (typeof(this.dialog.preload.architectureId) !== 'undefined'){
      this.getTopArchitecture(this.dialog.preload.architectureId)
    }else {
      this.getTopArchitecture()
    }
  },
  methods: {
    onClose(){
      this.agDialogClose()
    },
    onConfirmAction(){
      // if (this.selectEmployeeData.length === 0){
      //   UiKit.error("请选择人员")
      //   return;
      // }
      if (!this.isMultiple){
        let employee = this.selectEmployeeData[0]
        this.agDialogDispatchAction(employee)
      }else {
        this.agDialogDispatchAction(this.selectEmployeeData)
      }
      this.agDialogClose()
    },
    cancelSelect(employeeId) {
      this.selectEmployeeData = this.selectEmployeeData.filter(item => item.id !== employeeId);
      if (this.isMultiple){
        this.employeeList.forEach(item=>{
          if (item.id === employeeId){
            item.checked = false
          }
        })
        this.validateCheckedAll(this.employeeList)
      }
    },
    getTopArchitecture(id=0){
      let that = this
      ArchitectureApi.getTopArchitecture(id).exec().then(architectureTopRes=>{
        that.architectureList = deepClone(architectureTopRes.architectures)
        that.employeeList = deepClone(architectureTopRes.employees)
        if (this.isMultiple){
          that.checkedAll = false
          that.employeeList.forEach(item=>{
            if (this.validateIsChecked(item)){
              item.checked = true
            }
          })
          this.validateCheckedAll(that.employeeList)
        }
        that.getArchitectureBreadcrumb(id)
      });
    },
    getArchitectureBreadcrumb(id){
      let that = this
      ArchitectureApi.getArchitectureBreadcrumb(id).exec().then((res)=>{
        res.unshift({id:0, title:"通讯录"})
        that.breadcrumbs = res
      })
    },
    selectEmployee(employee){
      const newItem = deepClone(employee)
      if (!this.isMultiple){
        this.selectEmployeeData = [];
        this.selectEmployeeData.push(newItem)
      }else {
        if (!this.validateIsChecked(newItem)){
          newItem.checked = true
          this.selectEmployeeData.push(newItem)
          this.employeeList.forEach(item=>{
            if (item.id === newItem.id){
              item.checked = true
            }
          })
        }else {
          this.cancelSelect(employee.id)
        }
      }
      this.validateCheckedAll(this.employeeList)
    },
    checkAllItem(){
      if (!this.checkedAll){
        this.employeeList.forEach(item=>{
          this.cancelSelect(item.id)
        })
      }else {
        this.employeeList.forEach(item=>{
          item.checked = true
          if (!this.validateIsChecked(item)){
            const newItem = deepClone(item)
            newItem.checked = true
            this.selectEmployeeData.push(newItem)
          }
        })
      }
      this.validateCheckedAll(this.employeeList)
    },
    validateIsChecked(architecture){
      let isHas = false
      this.selectEmployeeData.forEach(item=>{
        if (item.id === architecture.id){
          isHas = true
        }
      })
      return isHas
    },
    validateCheckedAll(listData){
      let status = true
      listData.forEach(item=>{
        if (!this.validateIsChecked(item) && status){
          status = false
        }
      })
      this.checkedAll = status
    },
    async getEmployeeListByIds(ids){
      let that = this
      let listItems = await OaEmployeeApi.getEmployeeListByIds(ids).exec()
      for (let item of listItems) {
        if (!that.isMultiple &&that.selectEmployeeData.length>1){
          break;
        }
        const newItem = deepClone(item)
        that.selectEmployeeData.push(newItem)
      }
    }
  }
}
